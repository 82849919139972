<script setup>

</script>

<template>
  <v-sheet
    max-height="80"
    style="box-shadow: 0 15px 20px 0 rgba(50, 50, 50, 0.1)"
    class="position-sticky w-100 top-0 border-b bg-white pa-4 py-3 container d-flex justify-center align-center"
  >
    <v-sheet width="146">
      <router-link
        to="/"
        class="d-flex d-flex align-center justify-center text-grey-darken-3 text-decoration-none"
      >
        <v-img
          width="100%"
          height="auto"
          src="@/assets/Logo_Tom_Yam.svg"
        />
      </router-link>
    </v-sheet>
  </v-sheet>
</template>

<style scoped>
.container {
  z-index: 1000;
}
</style>
