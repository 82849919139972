<script setup>
import MenuItem from "@/components/Menu/MenuItem/MenuItem.vue";
import {useMenuStore} from "@/store/MenuStore";
import {storeToRefs} from "pinia";
import {computed} from "vue";
import {useDisplay} from "vuetify";
const menuStoreData = useMenuStore();
const isLoading = storeToRefs(menuStoreData).isLoading;
const { smAndDown, mdAndDown } = useDisplay();

const itemsToShow = computed(() => props.section.products
    .filter((product) => product.price > 0)
    .filter((product) => product.active)
    .sort((a, b) => a.priority - b.priority)
)

const props = defineProps({
  section: {
    type: Object,
    required: true,
  },
})
</script>

<template>
  <v-skeleton-loader
    :loading="isLoading"
    type="image"
  >
    <v-sheet class="mb-12 mx-0">
      <v-card-title
        :style="{fontSize: smAndDown ? '24px' : '40px', fontWeight: 700}"
        class="regular text-uppercase pa-0"
      >
        {{ props.section.title }}
      </v-card-title>
      <v-divider
        class="border-opacity-100 my-2"
        color="#aaa"
      />
      <v-sheet class="pa-0 ma-0 d-flex flex-column flex-md-row flex-wrap ga-4">
        <v-sheet
          v-for="product in itemsToShow"
          :key="product.id"
          class="pa-0 ma-0"
          :style="{width: mdAndDown ? '100%' : 'calc(50% - 8px)'}"
        >
          <MenuItem :product="product" />
        </v-sheet>
      </v-sheet>
    </v-sheet>
  </v-skeleton-loader>
</template>
