<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  isLoading: {
    type: Boolean,
    required: true,
  },
  placeholder: {
    type: String,
    required: true,
  },
  options: {
    type: Array,
    required: true,
  },
  modelValue: {
    type: Object,
  },
});

const emit = defineEmits(["update:modelValue"]);

// Локальное состояние для выбранного значения
const innerValue = ref(props.modelValue);
const isOpen = ref(false);
// Следим за изменениями в modelValue и обновляем локальное состояние
watch(
  () => props.modelValue,
  (newVal) => {
    innerValue.value = newVal;
  }
);

// Следим за изменением внутреннего значения и эмитим событие для обновления родительского компонента
watch(innerValue, (newVal) => {
  emit("update:modelValue", newVal);
});

const onClick = () => {
  isOpen.value = !isOpen.value;
};

const onBlur = () => {
  isOpen.value = false;
};
</script>

<template>
  <v-sheet class="pa-0 d-flex w-100 position-relative">
    <select
      v-model="innerValue"
      class="select px-4 py-3 w-100"
      :disabled="isLoading"
      @click="onClick"
      @blur="onBlur"
    >
      <option
        :value="null"
        disabled
        selected
        hidden=""
      >
        {{ placeholder }}
      </option>
      <option
        v-for="option in options"
        :key="option.value"
        :value="option"
      >
        {{ option.title }}
      </option>
    </select>
    <v-sheet
      :class="isOpen ? 'rotate' : 'rotate-back'"
      class="icon position-absolute bg-transparent"
    >
      <v-icon
        icon="mdi-menu-down"
        size="large"
      />
    </v-sheet>
  </v-sheet>
</template>

<style scoped>
.select {
  all: unset;
  border: none;
  cursor: pointer;
  background: #eeeeee;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #2b2b2b;
  width: auto;
  user-select: none;
  position: relative;

  option {
    all: unset;
    display: block;
    padding: 4px;
    transition: all 0.3s;

    &:hover {
      background: #e31d24;
    }
  }
}

.icon {
  top: calc(50% - 14px);
  right: 10px;
}

.rotate {
  transform: rotate(180deg);
  transition: all 0.3s;
}

.rotate-back {
  transform: rotate(360deg);
  transition: all 0.3s;
}
</style>
