<script setup>
import {computed, onMounted, ref} from "vue";
import {useAuthStore} from "@/store/AuthStore";
import {storeToRefs} from "pinia";
import BaseLayout from "@/components/Layouts/BaseLayout.vue";
import LeftAside from "@/components/Profile/components/LeftAside.vue";
import {useMenuStore} from "@/store/MenuStore";
import {useCartStore} from "@/store/CartStore";
import ProfileHeader from "@/components/Header/ProfileHeader.vue";
import {useProfileStore} from "@/store/ProfileStore";
import ProfileMobileMenu from "@/components/Profile/components/ProfileMobileMenu.vue";
import {useDisplay} from "vuetify";

const authStore = useAuthStore();
const menuStore = useMenuStore();
const cartStore = useCartStore();
const profileStore = useProfileStore();
const { token, user, isAuthenticated } = storeToRefs(authStore);
const isLoaded = ref(false);
const isLoading = computed(
    () =>  profileStore.isLoading
);
const {mdAndDown} = useDisplay();


onMounted(async () => {
  if (!isLoaded.value) {
    try {
      const sochiCity = menuStore.cities.find((city) => city.title === 'Сочи');
      await menuStore.getCities();
      await authStore.checkAuth();
      if (!isAuthenticated.value) {
        authStore.openLoginModal()
      }
      await menuStore.setCurrentCity(user.value.city);
      await menuStore.getMenuSections(token.value && user.value ? user.value.city.id : sochiCity.id);
      if (isAuthenticated.value) {
        await cartStore.getCart();
      }

      // Устанавливаем флаг, чтобы запросы не выполнялись снова
      isLoaded.value = true;
    } catch (error) {
      console.error(error);
    }
  }
});

</script>

<template>
  <base-layout :is-loading="false">
    <template #header>
      <ProfileHeader />
    </template>
    <v-sheet
      v-if="user !== null"
      class="d-flex flex-column w-100  pt-10"
    >
      <v-sheet
        class="text-h4 text-md-h3 w-100 mb-2 mb-md-8 text-customRed"
        style="{font-weight: 300}"
      >
        <span class="semibold">{{ user.name }},</span> рады вас видеть!
      </v-sheet>


      <v-row class="ga-6 flex-nowrap pb-10">
        <v-col
          v-if="!mdAndDown"
          cols="3"
        >
          <left-aside />
        </v-col>
        <v-col
          cols="12"
          lg="9"
        >
          <v-sheet class="pa-0 ma-0">
            <router-view />
          </v-sheet>
        </v-col>
      </v-row>

      <profile-mobile-menu />
    </v-sheet>
  </base-layout>
</template>

<style>
.default-profile-title {
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0.02em;
  text-align: left;

}
</style>
