<script setup>
import {ref } from "vue";
import { format } from "date-fns";
import {Field} from "vee-validate";

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  errorMessage: {
    type: String,
    required: false,
  },
  name: {
    type: String,
    required: true,
  },
  onValueChange: {
    type: Function,
    required: true,
  }
});

const isFieldDisabled = ref(true);
const isOpenDatepicker = ref(false);

// Локальная копия для `formattedDate`
const formattedDate = ref("");

// Обновляем родительское значение через `emit`
const handleInput = (date) => {
  formattedDate.value = format(new Date(date), "dd.MM.yyyy");
  props.onValueChange(date);
};
</script>

<template>
  <v-sheet class="d-flex flex-row justify-space-between align-end elevation-1 px-4 py-3 w-100">
    <v-sheet class="w-75">
      <v-sheet class="text-grey-darken-2 mb-1">
        {{ title }}
      </v-sheet>
      <v-sheet class="text-h6 w-100">
        <v-menu
          v-model="isOpenDatepicker"
          :close-on-content-click="false"
          transition="scale-transition"
        >
          <template #activator="{props: propsActivator}">
            <Field
              v-bind="propsActivator"
              :id="props.title"
              :class="{'border-b-md border-customRed enabled': !isFieldDisabled, 'border-error': errorMessage}"
              validate-on-input
              placeholder="-"
              style="all: unset"
              :name="props.name"
              readonly
              :disabled="isFieldDisabled"
            />
          </template>
          <v-date-picker
            hide-header
            locale="ru-Ru"
            title="День рождения"
            :max="new Date()"
            @update:model-value="handleInput"
          />
        </v-menu>
      </v-sheet>
      <span
        v-if="errorMessage"
        class="error-message"
      >{{ errorMessage }}</span>
    </v-sheet>
    <v-sheet
      class="cursor-pointer"
      @click="isFieldDisabled = !isFieldDisabled"
    >
      <v-img
        width="20"
        height="20"
        src="@/assets/red_pen_icon.svg"
      />
    </v-sheet>
  </v-sheet>
</template>

<style scoped>
.error-message {
  color: red;
  font-size: 0.8em;
}
.enabled {
  border-bottom: 2px solid #E31517 !important;
}
</style>
