<script setup>
import {ref} from "vue";
import {useCartStore} from "@/store/CartStore";

const props = defineProps({
  order: {
    type: Object,
    required: true,
  }
})

const isLoadingNewOrder = ref(false);
const cartStore = useCartStore();

const handleRepeatOrder = async () => {
  try {
    isLoadingNewOrder.value = true;
    await cartStore.addSeveralItemsToCart(props.order.items);
  } finally {
    isLoadingNewOrder.value = false;
  }
}
</script>

<template>
  <v-sheet class="text-customGray mb-5 mt-5">
    Детали заказа
  </v-sheet>
  <v-sheet
    v-for="item in order.items"
    :key="item.id"
    class="w-100 d-flex justify-space-between ga-4"
  >
    <v-sheet class="text-customRed text-h6 semibold">
      {{ item.quantity }} шт.
    </v-sheet>
    <v-sheet class="flex-1-0-0 semibold py-1">
      {{ item.product.title }}
    </v-sheet>
    <v-sheet class=" text-h6 semibold">
      {{ item.product.price }} ₽
    </v-sheet>
  </v-sheet>
  <v-divider
    class="my-6"
    thickness="2"
  />
  <v-sheet class="w-100 d-flex justify-start align-center ga-4">
    <v-sheet
      class=" d-flex justify-center align-center rounded-lg text-subtitle-2 cursor-pointer floating-button bg-customRed pa-2 px-3"
      @click.prevent.stop="handleRepeatOrder"
    >
      <v-sheet
        v-if="isLoadingNewOrder"
        class="d-flex justify-center align-center bg-transparent"
        style="width: 70px"
      >
        <v-progress-circular
          indeterminate
          color="customRed"
          size="small"
          width="2"
        />
      </v-sheet>
      <v-sheet
        v-else
        class="bg-transparent"
      >
        Повторить
      </v-sheet>
    </v-sheet>
    <v-sheet class="text-customGray semibold text-h5">
      Итого
    </v-sheet>
    <v-sheet class=" text-h4 semibold text-customRed ml-auto">
      {{ order.total_price }} ₽
    </v-sheet>
  </v-sheet>
</template>

<style scoped>

</style>
