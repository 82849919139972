<template>
  <v-chip class="pa-4">
    <template #default>
      <div class="text-h6 font-weight-medium mx-1">
        {{ props.value }}
      </div>
    </template>
    <template #prepend>
      <v-icon
        class="mr-3 cursor-pointer"
        icon="mdi-minus"
        size="medium"
        @click="props.handleClickMinus"
      />
    </template>
    <template #append>
      <v-icon
        class="ml-3 cursor-pointer"
        icon="mdi-plus"
        size="medium"
        @click="handleClickPlus"
      />
    </template>
  </v-chip>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  handleClickPlus: {
    type: Function,
    required: true,
  },
  handleClickMinus: {
    type: Function,
    required: true,
  },
  value: {
    type: Number,
    required: true,
  },
  loading: {
    type: Boolean,
    required: false,
    default: false,
  }
});
</script>
