<template>
  <v-sheet
    elevation="0"
    class="pa-0 mb-0"
  >
    <v-label
      v-if="label"
      class="text-black opacity-100 semibold mb-2"
    >
      {{
        label
      }}
    </v-label>
    <v-text-field
      v-model="innerValue"
      v-mask="customMask"
      unmasked
      v-bind="attrs"
      :error-messages="errorMessages"
      flat
      variant="underlined"
      bg-color="#FFFFFF"
      @update:model-value="updateValue"
    />
  </v-sheet>
</template>

<script setup>
import { ref, toRefs, useAttrs } from "vue";
import { VTextField, VLabel } from "vuetify/components";

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: "",
  },
  errorMessages: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  customMask: {
    type: String,
    // default: "",
    required: false,
  },
});

// Define the emit event
const emit = defineEmits(["update:modelValue"]);

// Destructure props
const { modelValue, errorMessages, label } = toRefs(props);


const innerValue = ref(modelValue.value);

// watch(modelValue, (newValue) => {
//   innerValue.value = newValue;
// });
// Function to handle input events and update the parent component
const updateValue = (value) => {
  innerValue.value = value;
  emit("update:modelValue", value);
};


// Capture all other attributes
const attrs = useAttrs();
</script>
