<script setup>
import BaseButton from "@/components/base/BaseButton.vue";

const props = defineProps({
  withButton: {
    type: Boolean,
    default: false,
    required: false,
  },
  onClick: {
    type: Function,
    required: false,
  }
})
</script>

<template>
  <v-sheet
    :class="props.withButton ? 'pa-6' : 'py-4'"
    class="w-100 rounded-xl d-flex justify-center align-center flex-column"
  >
    <v-row>
      <v-col
        md="2"
        cols="12"
        class="d-flex justify-center"
        :class="xs ? 'align-center' : 'align-start'"
      >
        <v-sheet
          style="width: 90px; height: 90px"
          border="customRed md"
          class="rounded-circle bg-transparent pa-2 border-solid border-opacity-100"
        >
          <v-img
            class=""
            width="90"
            height="90"
            src="@/assets/warn.svg"
          />
        </v-sheet>
      </v-col>
      <v-col
        md="10"
        cols="12"
        class="pa-0 pl-5"
      >
        <v-sheet class="d-flex flex-column ga-5 justify-center align-center flex-column w-100 pr-0">
          <v-sheet
            class="text-subtitle-1 font-weight-regular text-w lh-1 text-pre-wrap w-100 regular"
            :class="xs ? 'text-center' : 'text-left'"
            style="line-height: 1.3"
          >
            Обращаем Ваше внимание, что в наших блюдах содержатся продукты
            <span class="text-customRed">(мидии в створках, креветки в панцире, оригинальные специи и др.)
              и аллергены,</span>
            требующие особого внимания для Вашей безопасности. Будьте осторожны!
          </v-sheet>
          <v-sheet
            class="bg-transparent w-100 align-self-start"
            max-width="333"
          >
            <v-img
              class=""
              width="100%"
              height="auto"
              src="@/assets/perec.svg"
            />
          </v-sheet>
        </v-sheet>
      </v-col>
    </v-row>
    <v-sheet
      v-if="props.withButton"
      class="w-100 mt-8"
      max-width="250"
    >
      <base-button
        text="ОК"
        @click="props.onClick"
      />
    </v-sheet>
  </v-sheet>
</template>

<style scoped>

</style>
