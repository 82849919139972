<script setup>
import CartItemsList from "@/components/Cart/CartItemsList/CartItemsList.vue";
import CartHeader from "@/components/Cart/CartHeader/CartHeader.vue";
import CartDevices from "@/components/Cart/CartDevices/CartDevices.vue";
import CartCheck from "@/components/Cart/CartCheck/CartCheck.vue";
import CartAdditional from "@/components/Cart/CartAdditional/CartAdditional.vue";
import CartForm from "@/components/Cart/CartForm/CartForm.vue";
import {watch} from "vue";
import { useCartStore } from "@/store/CartStore";
import {storeToRefs} from "pinia";
import NotifyAboutIngredients from "@/components/modals/NotifyAboutIngredients.vue";
import NotificationAboutIngredients from "@/components/base/NotificationAboutIngredients.vue";
import {useDisplay} from "vuetify";

const cartStore = useCartStore();

const { order } = storeToRefs(cartStore);
const { mobile, mdAndDown, smAndDown } = useDisplay();

watch(order.value, (newVal)=> {
  if (newVal !== null) {
    window.location.replace(order.value.payment_url);
  }
})
</script>

<template>
  <v-sheet
    rounded
    elevation="0"
    class="pa-0 d-flex align-start flex-column w-100"
  >
    <v-sheet
      class="w-100 pa-0"
    >
      <cart-header />
      <v-sheet
        class="d-flex flex-column flex-md-row justify-space-between w-100 pa-0 ma-0 ga-4"
        justify="space-between"
      >
        <v-sheet
          class="pa-0 w-100"
          :style="{maxWidth: smAndDown ? 'none' : '556px'}"
        >
          <cart-items-list />
          <v-sheet class="pa-0 mt-4">
            <cart-additional />
          </v-sheet>
        </v-sheet>
        <v-sheet
          class="pa-0 w-100"
          :style="{maxWidth: smAndDown ? 'none' : '556px'}"
        >
          <v-sheet
            class="w-100 ma-0 gr-3 d-flex flex-column"
          >
            <v-sheet class="d-flex flex-column flex-lg-row align-stretch h-100 w-100 ga-lg-0 ga-3">
              <v-sheet
                min-height="100%"
                class="pa-0 px-sm-2 w-100"
              >
                <cart-devices />
              </v-sheet>
              <v-sheet
                class="pa-0 px-sm-2 w-100 "
              >
                <cart-check />
              </v-sheet>
            </v-sheet>
            <v-sheet class="w-100 pr-2 mb-2">
              <notification-about-ingredients :with-button="false" />
            </v-sheet>
          </v-sheet>
          <v-row>
            <cart-form />
          </v-row>
        </v-sheet>
      </v-sheet>
    </v-sheet>
    <notify-about-ingredients />
  </v-sheet>
</template>
