<script setup>
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});
</script>

<template>
  <v-container class="pa-0 w-100 pb-2">
    <v-row align="center">
      <v-col
        cols="8"
        class="text-subtitle-2"
      >
        <v-sheet class="d-flex justify-start align-center">
          <v-sheet>
            {{ props.item.name }}
          </v-sheet>
          <v-tooltip
            v-if="props.item.key === 'bags'"
            max-width="320px"
            text="Tooltip"
            theme="dark"
            content-class="bg-grey-lighten-3 rounded-xl"
            location="start"
          >
            <template #activator="{ props }">
              <v-sheet
                style="width: 13px; height: 13px"
                v-bind="props"
                class="semibold ml-1 fz-13 pa-0 bg-grey-lighten-1 text-white rounded-circle d-flex justify-center align-center"
              >
                ?
              </v-sheet>
            </template>
            <template #default>
              <v-sheet class="pa-2 w-100 bg-transparent text-grey-darken-3">
                Мы любим нашу планету и используем только бумажные крафтовые
                экологичные пакеты.
                <v-sheet class="semibold pa-0 w-100 bg-transparent">
                  Количество пакетов рассчитывается автоматически.
                </v-sheet>
              </v-sheet>
            </template>
          </v-tooltip>
        </v-sheet>
      </v-col>
      <v-col
        class="semibold d-flex align-center justify-end pa-2 text-no-wrap currency-symbol font-weight-bold"
        cols="4"
        style="line-height: 1"
      >
        {{ props.item.value }}
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.fz-11 {
  font-size: 11px;
  line-height: 1;
}
</style>
