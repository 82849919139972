<script setup>
import {boolean} from "yup";

const props = defineProps({
  onClick: {
    type: Function,
  },
  text: {
    type: String,
  },
  maxWidth: {
    type: Number,
  },
  uppercase: {
    type: boolean,
    required: false,
    default: false,
  },
  loading: {
    type: boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  }
});
</script>

<template>
  <v-btn
    class="rounded-xl semibold"
    color="#e31d24"
    width="100%"
    size="x-large"
    :loading="props.loading"
    flat
    :max-width="props.maxWidth"
    :disabled="props.disabled"
    @click="props.onClick"
  >
    <v-sheet
      class="bold bg-transparent text-subtitle-1"
      :class="props.uppercase ? 'text-uppercase' : ''"
    >
      {{ props.text }}
    </v-sheet>
  </v-btn>
</template>
