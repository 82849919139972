<!--TODO: Надо привести тут код в порядок, очень кривой получился -->
<script setup>
import BaseTitle from "@/components/base/BaseTitle/BaseTitle.vue";
import {computed, ref, watch} from "vue";
import BaseInput from "@/components/base/BaseInput/BaseInput.vue";
import BaseMaskedInput from "@/components/base/BaseMaskedInput/BaseMaskedInput.vue";
import { useField, useForm } from "vee-validate";
import { VLabel, VTextField } from "vuetify/components";
import { format } from "date-fns";
import { useCartStore } from "@/store/CartStore";
import { storeToRefs } from "pinia";
import {useAuthStore} from "@/store/AuthStore";
import {combineDateTime, isDatesEqual} from "@/helpers";
import DadataAdressAutocomplete from "@/components/base/DadataAdressAutocomplete.vue";
const cartStore = useCartStore();
const authStore = useAuthStore();
const { user } = storeToRefs(authStore);
const { selectedDeliveryType } = storeToRefs(cartStore);

const timeType = ref("now");
const menu = ref(false);
const formattedDate = ref("");
const name = useField("name", {}, {initialValue: user.value ? user.value.name : ''});
const phone = useField("phone", {}, {initialValue: user.value ? user.value.phone_number : ''});
const address = useField("address");
const fullDeliveryAddress = useField('fullAddress', {}, {initialValue: {}});
const apartment = useField("apartment");
const floor = useField("floor");
const domofon = useField("domofon");
const entrance = useField("entrance");
const comment = useField("comment");
const date = useField("date");
const time = useField("time");
const { handleSubmit, errors } = useForm({
  validationSchema: {
    name(value) {
      if (value?.length >= 2) return true;

      return "Имя должно содержать хотя бы 2 символа.";
    },
    phone(value) {
      if (/^[0-9-]{11,}$/.test(value)) return true;
      if (value) return true;
      return "Номер телефона должен быть 11 цифр.";
    },
    address(value) {
      if (value) return true;

      return "Поле обязательно";
    },
    apartment(value) {
      if (value) return true;

      return "Поле обязательно";
    },
    comment() {},
  },
});
const policyUrl = "https://tomyumbar.ru/privacy.pdf";
const showAddressError = ref(false);
const showCalculateDeliveryError = ref(false)
// Функция для форматирования даты
const formatDate = () => {
  if (date.value) {
    formattedDate.value = format(new Date(date.value.value), "dd.MM.yyyy");
    menu.value = false; // Закрывает меню после выбора даты
  }
};
const isOrderCreated = ref(false);
// Смотрим за изменениями в дате и форматируем ее
watch(date.value, formatDate);

watch(address.value, async () => {
  if (selectedDeliveryType.value === 'PICKUP') {
    await cartStore.getWorkSchedulePickup(address.value.value.id)
    await cartStore.calculateDelivery(null, time.value.value, 'PICKUP', false)
  }
})

watch(apartment.value, async (newVal) => {
  if (apartment.value !== null && apartment.value.length !== 0) {
    if (!fullDeliveryAddress.value.value.data) return;
    const addressData = fullDeliveryAddress.value.value.data;

    const formAddress = {
      inner_city: addressData.city,
      kladr_id: addressData.kladr_id,
      street_type: addressData.street_type,
      street: addressData.street,
      house: addressData.house,
      geo_lat: addressData.geo_lat,
      geo_lon: addressData.geo_lon,
      fias_id: addressData.fias_id,
      source: fullDeliveryAddress.value.value.value,
      floor: floor.value.value,
      entrance: entrance.value.value,
      apartment: apartment.value.value,
    }
    showCalculateDeliveryError.value = newVal.length === 0;
    cartStore.toggleDeliveryPrice(newVal.length !== 0);
    if (hasHouse.value) {
      await cartStore.calculateDelivery(formAddress, time.value.value, 'COURIER', false)
      await cartStore.getWorkScheduleDelivery(formAddress)
    }
  }
})

watch(selectedDeliveryType, () => {
  cartStore.clearDeliveryTime();
})

watch(fullDeliveryAddress.value, async (newVal) => {
  if (!fullDeliveryAddress.value.value || !newVal.value) return;
  showCalculateDeliveryError.value = true;
  const addressData = fullDeliveryAddress.value.value.data;

  const formAddress = {
    inner_city: addressData.city,
    kladr_id: addressData.kladr_id,
    street_type: addressData.street_type,
    street: addressData.street,
    house: addressData.house,
    geo_lat: addressData.geo_lat,
    geo_lon: addressData.geo_lon,
    fias_id: addressData.fias_id,
    source: fullDeliveryAddress.value.value.value,
    floor: floor.value.value,
    entrance: entrance.value.value,
    apartment: apartment.value.value,
  }

  // Указываем поля, которые нужно исключить из проверки
  const excludedFields = ['floor', 'entrance'];

  const isFormAddressComplete = Object.entries(formAddress)
      .filter(([key]) => !excludedFields.includes(key)) // Исключаем поля
      .every(([, value]) => value !== undefined && value !== null && value !== '');

  if (isFormAddressComplete) {
    await cartStore.getWorkScheduleDelivery(formAddress)
  }

  apartment.value.value = '';
}, {
  deep: true
})

watch(time.value, async () => {
  if (!time.value.value || selectedDeliveryType.value === 'PICKUP') return;
  const addressData = fullDeliveryAddress.value.value.data;

  const formAddress = {
    inner_city: addressData.city,
    kladr_id: addressData.kladr_id,
    street_type: addressData.street_type,
    street: addressData.street,
    house: addressData.house,
    geo_lat: addressData.geo_lat,
    geo_lon: addressData.geo_lon,
    fias_id: addressData.fias_id,
    source: fullDeliveryAddress.value.value.value,
    floor: floor.value.value,
    entrance: entrance.value.value,
    apartment: apartment.value.value,
  }
  const excludedFields = ['floor', 'entrance'];
  const isFormAddressComplete = Object.entries(formAddress)
      .filter(([key]) => !excludedFields.includes(key)) // Исключаем поля
      .every(([, value]) => value !== undefined && value !== null && value !== '');
  if (isFormAddressComplete) {
    await cartStore.calculateDelivery(formAddress, time.value.value, 'COURIER', false)
  }
}, {
  deep: true
})

watch(date.value, () => {
  if (cartStore.schedulePickUpHours && cartStore.schedulePickUpHours.length !== 0) {
    const currentDateHours = cartStore.schedulePickUpHours.map((avDate) => {
      const isSameDates = isDatesEqual(formattedDate.value, 'dd.MM.yyyy', avDate.date, 'yyyy-MM-dd');
      if (isSameDates) {
        timeOptions.value = avDate.hours;
      }
    });
  } else{
    return [];
  }
})

const isButtonDisabled = computed(() => {
  if (selectedDeliveryType.value === 'PICKUP') {
    if (address.value.value === undefined || name.value.value.length === 0 || phone.value.value.length < 11) return true;

    if (timeType.value === 'now') return false;

    if ( time.value.value !== undefined ) {
      return time.value.value.length === 0;
    }
    return true;
  } else {
    if (hasHouse.value) {
      return !(apartment.value.value !== undefined && apartment.value.value.length !== 0);
    } else {
      return true;
    }
  }
})

const hasHouse = computed(() => Boolean(fullDeliveryAddress.value?.value?.data?.house) );

const isDatePickerDisabled = computed(() => {
  if (pickupAllowedDates.value.length === 0) return true;
  if (selectedDeliveryType.value === 'PICKUP') {
    return !(address.value.value !== undefined);
  } else {
    return !hasHouse.value;
  }
} );

const pickupAllowedDates = computed(() => {
  return cartStore.schedulePickUpHours.map((item) => item.date)
})

const timeOptions = ref([]);

const onBlurAddressField = (e) => {
  showAddressError.value = !hasHouse.value && e.target.value.length > 0;
}

const handleSelectItem = (item) => {
  showAddressError.value = !hasHouse.value;
}

const handleCreateOrder = async () => {
  try {
    const addressId = address.value?.value?.id ? address.value.value.id : undefined;
    isOrderCreated.value = true;
    const addressData = fullDeliveryAddress.value.value.data;
    const realDate = combineDateTime(time.value.value, date.value.value, user.value.city.timezone)
    const realComment =  comment.value.value
    const formAddress = addressData ? {
      inner_city: addressData.city,
      kladr_id: addressData.kladr_id,
      street_type: addressData.street_type,
      street: addressData.street,
      house: addressData.house,
      geo_lat: addressData.geo_lat,
      geo_lon: addressData.geo_lon,
      fias_id: addressData.fias_id,
      source: fullDeliveryAddress.value.value.value,
      floor: floor.value.value,
      entrance: entrance.value.value,
      apartment: apartment.value.value,
    } : null;
    await cartStore.createOrder(formAddress, realDate, selectedDeliveryType.value, realComment, addressId)

  } catch (e) {
    console.error(e);
  } finally {
    isOrderCreated.value = false;
  }
}

</script>

<template>
  <v-sheet
    class="w-100 pa-0 d-flex justify-center align-center flex-column pa-3"
  >
    <base-title class="mb-4">
      Оформление заказа
    </base-title>
    <v-sheet class="pa-0 w-100 mb-4">
      <v-tabs
        v-model="selectedDeliveryType"
        color="white"
        bg-color="rgba(0, 0, 0, 0.05)"
        class="rounded-xl w-100 d-flex"
      >
        <v-tab
          value="COURIER"
          rounded="xl"
          selected-class="bg-customRed"
          class="ma-0 w-100 flex-1-0-0"
          text="Доставка курьером"
        />

        <v-tab
          value="PICKUP"
          rounded="xl"
          selected-class="bg-customRed"
          class="ma-0 w-100 flex-1-0-0"
          text="Самовывоз из ресторана"
        />
      </v-tabs>
    </v-sheet>

    <v-sheet
      class="w-100 pa-0"
    >
      <base-input
        v-model="name.value.value"
        :error-messages="name.errorMessage.value"
        label="Ваше имя*"
        placeholder="Введите Ваше имя"
      />

      <base-masked-input
        v-model="phone.value.value"
        :error-messages="phone.errorMessage.value"
        label="Номер телефона*"
        placeholder="+7 () ___ - __ - __"
        custom-mask="+7 (###) ###-##-##"
      />

      <v-sheet width="100%">
        <v-tabs-window v-model="selectedDeliveryType">
          <v-tabs-window-item value="COURIER">
            <v-sheet
              fluid
              class="pa-0"
            >
              <v-sheet
                class="w-100 pa-0 d-flex justify-center align-center flex-column"
              >
                <dadata-adress-autocomplete
                  v-model="fullDeliveryAddress.value.value"
                  :error-messages="fullDeliveryAddress.errorMessage.value"
                  placeholder="Адрес"
                  label="Город, улица и дом*"
                  :on-blur="onBlurAddressField"
                  :on-select="handleSelectItem"
                />
                <v-row class="w-100 pa-0 ga-4">
                  <v-col class="pa-0">
                    <base-input
                      v-model="apartment.value.value"
                      :error-messages="apartment.errorMessage.value"
                      label="Квартира*"
                      placeholder="№"
                    />
                  </v-col>
                  <v-col class="pa-0">
                    <base-input
                      v-model="floor.value.value"
                      :error-messages="floor.errorMessage.value"
                      label="Этаж"
                    />
                  </v-col>
                  <v-col class="pa-0">
                    <base-input
                      v-model="domofon.value.value"
                      :error-messages="domofon.errorMessage.value"
                      label="Домофон"
                    />
                  </v-col>
                  <v-col class="pa-0">
                    <base-input
                      v-model="entrance.value.value"
                      :error-messages="entrance.errorMessage.value"
                      label="Подъезд"
                    />
                  </v-col>
                </v-row>
              </v-sheet>
              <v-sheet
                v-if="showCalculateDeliveryError"
                class="d-flex flex-row align-center ga-2 mb-4"
              >
                <v-sheet>
                  <v-img
                    class=""
                    width="5px"
                    src="@/assets/warn.png"
                  />
                </v-sheet>
                <v-sheet class="semibold">
                  Пожалуйста, заполните номер квартиры для расчета доставки!
                </v-sheet>
              </v-sheet>
              <v-sheet
                v-if="showAddressError"
                class="d-flex flex-row align-center ga-2 mb-4"
              >
                <v-sheet>
                  <v-img
                    class=""
                    width="5px"
                    src="@/assets/warn.png"
                  />
                </v-sheet>
                <v-sheet class="semibold">
                  Адрес введен некорректно!<br>
                  Пожалуйста воспользуетесь адресом из подсказок.
                </v-sheet>
              </v-sheet>
            </v-sheet>
          </v-tabs-window-item>
          <v-tabs-window-item value="PICKUP">
            <v-container
              fluid
              class="pa-0 w-100"
            >
              <v-sheet
                class="w-100 pa-0 d-flex justify-center align-start flex-column"
              >
                <v-label
                  class="text-black opacity-100 semibold mb-2 text-start"
                >
                  Откуда хотите забрать заказ?
                </v-label>
                <v-select
                  v-model="address.value.value"
                  class="w-100"
                  flat
                  :error-messages="address.errorMessage.value"
                  variant="underlined"
                  density="compact"
                  return-object
                  :items="user.city.addresses"
                />
              </v-sheet>
            </v-container>
          </v-tabs-window-item>
        </v-tabs-window>
      </v-sheet>

      <v-sheet class="pa-0 w-100 mb-4">
        <v-tabs
          v-model="timeType"
          color="white"
          bg-color="rgba(0, 0, 0, 0.05)"
          class="rounded-xl"
        >
          <v-tab
            value="now"
            rounded="xl"
            selected-class="bg-customRed"
            text="Ближайшее время"
            class="ma-0 w-100 flex-1-0-0"
          />

          <v-tab
            value="pickup"
            rounded="xl"
            selected-class="bg-customRed"
            class="ma-0 w-100 flex-1-0-0"
            text="Выбрать время"
          />
        </v-tabs>
      </v-sheet>

      <v-sheet width="100%">
        <v-tabs-window v-model="timeType">
          <v-tabs-window-item value="now">
            <v-container
              fluid
              class="pa-0"
            />
          </v-tabs-window-item>
          <v-tabs-window-item value="pickup">
            <v-container
              fluid
              class="pa-0 w-100"
            >
              <v-row>
                <v-col>
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template #activator="{ props }">
                      <v-label class="text-black opacity-100 semibold mb-2">
                        Выберите дату
                      </v-label>
                      <v-text-field
                        v-model="formattedDate"
                        :error-messages="date.errorMessage.value"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="props"
                        flat
                        variant="underlined"
                        density="compact"
                        bg-color="#FFFFFF"
                      />
                    </template>
                    <v-date-picker
                      v-model="date.value.value"
                      hide-header
                      :disabled="isDatePickerDisabled"
                      :allowed-dates="pickupAllowedDates"
                      locale="ru-Ru"
                      title="Выберите дату"
                      @input="menu = false"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  :class="isDatePickerDisabled ? 'd-flex align-items-center' : ''"
                >
                  <v-label
                    class="text-black opacity-100 semibold mb-2 text-start"
                  >
                    {{ !isDatePickerDisabled ? 'Время доставки' : 'Введите адрес' }}
                  </v-label>
                  <v-select
                    v-if="!isDatePickerDisabled"
                    v-model="time.value.value"
                    class="w-100"
                    flat
                    :error-messages="time.errorMessage.value"
                    variant="underlined"
                    density="compact"
                    :items="timeOptions"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-tabs-window-item>
        </v-tabs-window>
      </v-sheet>

      <v-label
        class="text-black opacity-100 semibold mb-0"
      >
        Комментарий к заказу
      </v-label>
      <v-textarea
        v-model="comment.value.value"
        :error-messages="comment.errorMessage.value"
        variant="underlined"
        class="pa-0"
        row-height="15"
        rows="2"
        density="compact"
      />

      <v-checkbox>
        <template #label>
          <v-label class="text-black opacity-100 semibold">
            Не перезванивать для подтверждения заказа
          </v-label>
        </template>
      </v-checkbox>

      <v-sheet class="w-100 pa-0 text-subtitle-2 semibold custom-line-height">
        Нажимая кнопку «Перейти к оплате», Вы даёте согласие на обработку своих
        персональных данных, в соответствии с Федеральным законом от 27.07.2006
        года №152-ФЗ «О персональных данных», на условиях и для целей,
        определенных
        <a
          class="text-decoration-underline cursor-pointer text-customRed semibold d-inline"
          :href="policyUrl"
          target="_blank"
        >Политикой конфиденциальности.</a>
      </v-sheet>

      <v-btn
        class="rounded-xl mt-4 py-5"
        height="auto"
        color="customRed"
        width="100%"
        :disabled="isButtonDisabled"
        :loading="isOrderCreated"
        @click="handleCreateOrder"
      >
        Перейти к оплате
      </v-btn>
    </v-sheet>
  </v-sheet>
</template>

<style scoped>
.custom-line-height {
  line-height: 1.5;
}
</style>
