<script setup>
import CustomRouterLink from "@/components/Profile/components/CustomRouterLink.vue";
import {computed} from "vue";
import {useMenuStore} from "@/store/MenuStore";
import {storeToRefs} from "pinia";

const menuStore = useMenuStore();
const { currentCity } = storeToRefs(menuStore);
const contacts = computed(() => {
  return currentCity.value ? currentCity.value.contacts : [];
})

const email = computed(() => contacts.value.find((contact) => contact.title === 'email'))

const telegram = computed(() => contacts.value.find((contact) => contact.title === 'Telegram'))

const phone = computed(() => contacts.value.find((contact) => contact.title === 'phone'))

const whatsApp = computed(() => contacts.value.find((contact) => contact.title === 'WhatsApp'))

const socialLinksData = [
  {
    icon:  require('@/assets/IconPhone.svg'),
    link: phone.value ? phone.value.link : '',
    title: 'Телефон'
  },
  {
    icon:  require('@/assets/IconEmail.svg'),
    link: email.value ? email.value.link : '',
    title: 'Email'
  },
  {
    icon:  require('@/assets/iconWhatsApp.svg'),
    link: whatsApp.value ? whatsApp.value.link : '',
    title: 'WhatsApp'
  },
  {
    icon:  require('@/assets/iconTg.svg'),
    link: telegram.value ? telegram.value.link : '',
    title: 'Telegram'
  }
]
</script>

<template>
  <v-sheet class="d-flex flex-column ga-2 mb-7">
    <custom-router-link to="/profile/order">
      Текущий заказ
    </custom-router-link>
    <custom-router-link to="/profile/history">
      История заказов
    </custom-router-link>
    <custom-router-link to="/profile/edit">
      Профиль
    </custom-router-link>
  </v-sheet>
  <v-sheet class="pa-0 ma-0 d-flex flex-column align-center">
    <v-sheet class="text-subtitle-1 ma-0 mb-3 semibold">
      Связаться с нами
    </v-sheet>
    <v-sheet class="pa-0 ma-0 d-flex flex-row align-center justify-space-between ga-5">
      <a
        v-for="social in socialLinksData"
        :key="social"
        target="_blank"
        :href="social.link"
        class="socialLink"
      >
        <v-img
          width="20"
          height="20"
          :src="social.icon"
        />
      </a>
    </v-sheet>
  </v-sheet>
  <v-sheet class="mt-5">
    <v-img
      src="@/assets/banners/profile_banner_left.png"
      width="100%"
      height="auto"
    />
  </v-sheet>
</template>

<style scoped>
.socialLink {
  text-decoration: none;
  background: #e31d24;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all .3s;
  cursor: pointer;

  &:hover {
    background: #ec4148;
  }
}
.v-list-item__content {
  width: 100%;
}
</style>
