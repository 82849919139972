<template>
  <base-layout :is-loading="isLoading">
    <template #header>
      <HeaderComponent />
    </template>
    <MainPage />
  </base-layout>
</template>

<script setup>
import {computed, onBeforeMount, watch} from "vue";
import {useMenuStore} from "@/store/MenuStore";
import BaseLayout from "@/components/Layouts/BaseLayout.vue";
import MainPage from "@/components/Home/MainComponent.vue";
import HeaderComponent from "@/components/Header/HeaderComponent.vue";
import router from "@/router";
import {useAuthStore} from "@/store/AuthStore";

const props = defineProps({
    city: {
      type: Object,
      required: true,
    }
})
const authStore = useAuthStore();
const menuStore = useMenuStore();

const isLoading = computed(
    () => menuStore.isLoadingSections || menuStore.isLoadingCities || authStore.isLoading
);
onBeforeMount(async () => {
  await menuStore.setCurrentCity(props.city)
})

watch(router.currentRoute, async (newVal) => {
  await router.push({
    path: `${newVal.fullPath}`,
  })
})

</script>
