<script setup>
import {computed, ref} from 'vue';
import { useAuthStore } from '@/store/AuthStore';
import {useMenuStore} from "@/store/MenuStore";
import {storeToRefs} from "pinia";
import BaseInput from "@/components/base/BaseInput/BaseInput.vue";
import * as yup from "yup";
import {useField, useForm} from "vee-validate";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import {removeNonNumeric} from "@/helpers";
import router from "@/router";
import CyrillicToTranslit from "cyrillic-to-translit-js"; // Импортируем Pinia store для авторизации

// Стор авторизации (предположим, что вы уже создали стор с методами requestVerificationCode и login)
const authStore = useAuthStore();
const menuStore = useMenuStore();

const { isAuthModalOpen } = storeToRefs(authStore)
const { cities } = storeToRefs(menuStore);

// Локальные переменные для формы
const isLoading = ref(false);
const currentStep = ref(1);
const selectedCity = ref(null);
const isAgreeChecked = ref(false);

const schema = yup.object({
  phoneNumber: yup
      .string()
      .required('Введите номер телефона')
      .transform((value) => value.replace(/[^\d]/g, ''))
      .matches(/^\d{11}$/, 'Введите корректный номер телефона'),
  name: yup.string().required('Введите имя').min(3, 'Имя не должно быть короче 3 символов'),
  verificationCode: yup
      .string()
      .when('currentStep', {
        is: 2, // Проверка кода только на втором шаге
        then: yup.string().required('Введите код верификации'),
      }),
});

const { errors } = useForm({
  validationSchema: schema,
});

// Определяем поля формы
const { value: phoneNumber } = useField('phoneNumber');
const { value: verificationCode } = useField('verificationCode');
const { value: name } = useField('name', {}, {validateOnValueUpdate: true});


// Шаг 1: Отправить запрос на получение кода верификации
const requestVerificationCode = async () => {
  isLoading.value = true;
  try {
    // Вызываем метод из Pinia store для отправки номера телефона
    await authStore.requestVerificationCode(phoneNumber.value);
    currentStep.value = 2; // Переходим на второй шаг (ввод кода)
  } catch (error) {
    console.error('Ошибка запроса кода верификации:', error);
  } finally {
    isLoading.value = false;
  }
};

// Шаг 2: Авторизация по коду верификации
const login = async () => {
  isLoading.value = true;
  try {
    await authStore.login(verificationCode.value, selectedCity.value.id, name.value);
    const userCityRouteName = CyrillicToTranslit().transform(selectedCity.value.title).toLowerCase();

    authStore.closeLoginModal();
    await router.push({
      path: `/${userCityRouteName}`
    })
  } catch (error) {
    console.error('Ошибка при авторизации:', error);
  } finally {
    isLoading.value = false;
  }
};

// Сброс формы и возврат на первый шаг
const resetForm = () => {
  phoneNumber.value = '';
  verificationCode.value = '';
  currentStep.value = 1;
};

const isButtonDisabled = computed(() => {
  if (!phoneNumber.value) return true;
  if (removeNonNumeric(phoneNumber.value).length !== 11) return true;
  if (!selectedCity.value) return true;
  if (!isAgreeChecked.value) return true;
  if (!name.value) return true;
  return false
});

const handleToggleChecked = () => {
  isAgreeChecked.value = !isAgreeChecked.value
}

</script>

<template>
  <v-dialog
    v-model="isAuthModalOpen"
    width="100%"
    max-width="500"
  >
    <v-sheet
      rounded="xl"
      class="pt-6 pb-12 px-8 d-flex justify-center align-center flex-column"
    >
      <v-sheet
        width="100%"
        max-width="260"
      >
        <v-img src="@/assets/Logo_Tom_Yam.svg" />
      </v-sheet>
      <v-card-text class="text-center text-customRed text-h4 font-weight-bold">
        {{ currentStep === 1 ? "Вход" : "Введите код верификации" }}
      </v-card-text>

      <v-form
        v-if="currentStep === 1"
        ref="form"
        class="w-100"
      >
        <v-sheet
          class="d-flex justify-center align-center flex-column ga-4 px-4"
        >
          <base-input
            v-model="name"
            placeholder="Ваше имя"
            no-bottom-padding
            :error-messages="errors.name"
          />
          <base-input
            v-model="phoneNumber"
            placeholder="+7 (999) 123-45-67"
            no-bottom-padding
            :error-messages="errors.phoneNumber"
            custom-mask="+{7}(000)000-00-00"
          />
          <v-sheet
            class="w-100 pa-0 d-flex justify-center align-start flex-column"
          >
            <base-select
              v-model="selectedCity"
              :options="cities"
              placeholder="Выберите город"
              :is-loading="isLoading"
            />
          </v-sheet>
          <v-sheet class="w-100 pa-0">
            <v-checkbox
              v-model="isAgreeChecked"
              class="pa-0 ma-0 mr-2"
              hide-details
              min-height="none"
              density="compact"
            >
              <template #input>
                <div
                  class="custom-checkbox pa-0"
                  :class="{
                    'custom-checkbox-checked': isAgreeChecked,
                    'custom-checkbox-disabled': false
                  }"
                  @click="handleToggleChecked"
                >
                  <v-icon
                    v-if="isAgreeChecked"
                    color="white"
                  >
                    mdi-check
                  </v-icon>
                </div>
              </template>
              <template #label>
                <v-card-text class="custom-checkbox-label semibold pa-0">
                  Авторизовываясь, вы соглашаетесь с&nbsp;<a
                    class="text-pre d-inline"
                    href="https://sochi.tomyumbar.ru/privac%D1%83.pdf"
                    target="_blank"
                  >правилами обработки данных</a>
                </v-card-text>
              </template>
            </v-checkbox>
          </v-sheet>
          <v-sheet
            max-width="220"
            class="pa-0 ma-0"
          >
            <base-button
              :disabled="isButtonDisabled"
              :loading="isLoading"
              text="Получить код"
              @click="requestVerificationCode"
            />
          </v-sheet>
        </v-sheet>
      </v-form>

      <v-form
        v-else
        ref="form"
      >
        <v-otp-input
          v-model="verificationCode"
          :length="4"
          variant="underlined"
          :error-messages="errors.verificationCode"
        />
        <v-sheet
          max-width="220"
          class="pa-0 ma-0 mx-auto"
        >
          <base-button
            :loading="isLoading"
            text="Войти"
            @click="login"
          />
        </v-sheet>
      </v-form>
    </v-sheet>
  </v-dialog>
</template>

<style scoped>
.custom-checkbox {
  all: unset;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 2px solid #000;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background 0.3s, border 0.3s;
}

.custom-checkbox-checked {
  background-color: #e31d24;
  border-color: #e31d24;
}

.custom-checkbox-disabled {
  background-color: #f5f5f5;
  border-color: #dcdcdc;
  cursor: not-allowed;
}

.custom-checkbox-label {
  margin-left: 8px;
  font-size: 14px;
  line-height: 1.2;
}
</style>
