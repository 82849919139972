<script setup>
import {ref} from "vue";
import BaseButton from "@/components/base/BaseButton.vue";
import {useDisplay} from "vuetify";
import NotificationAboutIngredients from "@/components/base/NotificationAboutIngredients.vue";
const { xs } = useDisplay();

const isOpen = ref(true);
const handleCLiskButton = () => {
  isOpen.value = false;
}

</script>

<template>
  <v-dialog
    :close-on-back="true"
    :model-value="isOpen"
    persistent
    width="100%"
    :max-width="xs ? 'none' : 780"
    :fullscreen="xs"
  >
    <notification-about-ingredients
      :with-button="true"
      :on-click="handleCLiskButton"
    />
  </v-dialog>
</template>

<style scoped>

</style>
