<script setup>
import {storeToRefs} from "pinia";
import {useCartStore} from "@/store/CartStore";
import router from "@/router";
import {getCurrencySymbol} from "@/helpers";
import {useMenuStore} from "@/store/MenuStore";

const { total_price } = storeToRefs(useCartStore());
const { currentCity } = storeToRefs(useMenuStore())

const handleNavigateToCart = () => {
  router.push({
    path: "/cart",
  });
};

const handleNavigateToFooter = () => {
  router.push({
    path: "/",
    hash: `#footer`,
  });
}

</script>

<template>
  <v-sheet
    class="position-fixed bottom-0 left-0 w-100 rounded-t-lg h-auto py-3 px-2 d-flex flex-row align-center justify-space-between"
    style="background: rgba(227, 30, 36, 0.9); z-index: 1000"
  >
    <v-sheet class="bg-transparent">
      <v-btn
        class="rounded-xl semibold h-auto bg-transparent "
        style="border-bottom: 1px solid #fff"
        color="#fff"
        size="x-small"
        flat
        @click="handleNavigateToFooter"
      >
        <v-sheet
          class="bold bg-transparent text-white pa-1"
          style="line-height: 1; font-size:10px"
        >
          Контакты
        </v-sheet>
      </v-btn>
    </v-sheet>
    <v-sheet class="d-flex flex-row align-center justify-end ga-2 bg-transparent">
      <v-icon
        icon="mdi-cart-outline"
        size="x-small"
        color="white"
      />
      <v-sheet
        style="line-height: 1; font-size:12px"
        class="semibold bg-transparent text-white currency-symbol"
      >
        {{ total_price }}&nbsp;{{ getCurrencySymbol(currentCity) }}
      </v-sheet>
      <v-btn
        class="rounded-xl semibold h-auto"
        color="#fff"
        size="x-small"
        flat
        @click="handleNavigateToCart"
      >
        <v-sheet
          class="bold bg-transparent text-customRed pa-2"
          style="line-height: 1; font-size:12px"
        >
          ОФОРМИТЬ ЗАКАЗ
        </v-sheet>
      </v-btn>
    </v-sheet>
  </v-sheet>
</template>

<style scoped>

</style>
