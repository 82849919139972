<template>
  <v-sheet
    rounded="5"
    class="w-100 pa-4 pb-8 rounded-xl h-100"
    style="box-shadow: 0 0 11px rgba(0, 0, 0, 0.05);"
  >
    <slot />
  </v-sheet>
</template>

<script setup></script>
