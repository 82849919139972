<script setup>

</script>

<template>
  <v-sheet class="d-flex flex-row ga-5">
    <v-sheet>
      <slot name="icon" />
    </v-sheet>
    <v-sheet class="d-flex flex-column ga-1">
      <v-sheet class="text-grey-darken-1">
        <slot name="title" />
      </v-sheet>
      <v-sheet class="semibold">
        <slot name="value" />
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>

<style scoped>

</style>
