<script setup>
 const props = defineProps({
   title: {
     type: String,
     required: true,
   }
 })
</script>

<template>
  <v-sheet class="ma-0 pa-0 d-flex flex-column align-center align-md-start bg-transparent">
    <v-sheet
      style="font-size: 24px; line-height: 1"
      class=" bg-transparent semibold text-uppercase text-no-wrap mb-2"
    >
      {{ props.title }}:
    </v-sheet>
    <v-sheet class="ma-0 pa-0 w-100 bg-transparent">
      <slot />
    </v-sheet>
  </v-sheet>
</template>

<style scoped>

</style>
