<script setup>
import BaseButton from "@/components/base/BaseButton.vue";

const props = defineProps({
  quantity: {
    type: Number,
  },
  addProduct: {
    type: Function,
    required: true,
  },
  removeProduct: {
    type: Function,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  }
});

</script>
<template>
  <v-sheet class="pa-0">
    <base-button
      v-if="props.quantity === 0"
      max-width="160"
      height="40"
      :loading="props.isLoading"
      text="В корзину"
      :uppercase="true"
      @click="addProduct"
    />
    <v-sheet
      v-else
      width="160"
      height="40"
      class="d-flex align-center justify-space-between rounded-xl bg-customRed"
    >
      <v-btn
        class="bg-customRed"
        width="40"
        height="40"
        flat
        icon
        :loading="props.isLoading"
        @click="removeProduct"
      >
        <v-icon>mdi-minus</v-icon>
      </v-btn>

      <span class="mx-4">{{ props.quantity }}</span>

      <v-btn
        class="bg-customRed"
        width="40"
        height="40"
        flat
        icon
        :loading="isLoading"
        @click="addProduct"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-sheet>
  </v-sheet>
</template>
