<script setup>
import CustomRouterLink from "@/components/Profile/components/CustomRouterLink.vue";
import {computed, ref} from "vue";
import {useMenuStore} from "@/store/MenuStore";
import {storeToRefs} from "pinia";
import {useProfileStore} from "@/store/ProfileStore";
import {useDisplay} from "vuetify";
import BaseButton from "@/components/base/BaseButton.vue";
import router from "@/router";
import {useAuthStore} from "@/store/AuthStore";

const menuStore = useMenuStore();
const profileStore = useProfileStore();
const authStore = useAuthStore();
const { currentCity } = storeToRefs(menuStore);
const contacts = computed(() => {
  return currentCity.value ? currentCity.value.contacts : [];
})

const email = computed(() => contacts.value.find((contact) => contact.title === 'email'))

const telegram = computed(() => contacts.value.find((contact) => contact.title === 'Telegram'))

const phone = computed(() => contacts.value.find((contact) => contact.title === 'phone'))

const whatsApp = computed(() => contacts.value.find((contact) => contact.title === 'WhatsApp'))

const socialLinksData = [
  {
    icon:  require('@/assets/IconPhone.svg'),
    link: phone.value ? phone.value.link : '',
    title: 'Телефон'
  },
  {
    icon:  require('@/assets/IconEmail.svg'),
    link: email.value ? email.value.link : '',
    title: 'Email'
  },
  {
    icon:  require('@/assets/iconWhatsApp.svg'),
    link: whatsApp.value ? whatsApp.value.link : '',
    title: 'WhatsApp'
  },
  {
    icon:  require('@/assets/iconTg.svg'),
    link: telegram.value ? telegram.value.link : '',
    title: 'Telegram'
  }
];

const handleLogout = () => {
  router.push({
    path: "/",
  });
  authStore.logout();
}

</script>

<template>
  <v-navigation-drawer
    v-model="profileStore.isMobileDrawerOpen"
    location="left"
    temporary
  >
    <v-list-item
      :src="authStore.user.avatar_url"
      :prepend-avatar="authStore.user.avatar_url"
      :title="`${ authStore.user.name } ${ authStore.user.last_name }`"
    />

    <v-divider class="my-4" />

    <v-list>
      <v-sheet class="d-flex flex-column ga-2 mb-7">
        <custom-router-link to="/profile/order">
          Текущий заказ
        </custom-router-link>
        <custom-router-link to="/profile/history">
          История заказов
        </custom-router-link>
        <custom-router-link to="/profile/edit">
          Профиль
        </custom-router-link>
      </v-sheet>
    </v-list>

    <v-divider />

    <v-sheet class="text-subtitle-1 ma-0 mb-3 semibold text-center pt-4">
      Связаться с нами
    </v-sheet>

    <v-list>
      <v-list-item
        v-for="social in socialLinksData"
        :key="social"
      >
        <v-sheet class="w-100 d-flex flex-row ga-3">
          <v-sheet
            width="40"
            class="d-flex align-center justify-center rounded-lg pa-2 "
            style="background: #e31d24;"
          >
            <v-img
              width="100%"
              height="auto"

              :src="social.icon"
            />
          </v-sheet>
          <v-sheet class="w-100">
            <a :href="social.link">{{ social.title }}</a>
          </v-sheet>
        </v-sheet>
      </v-list-item>
    </v-list>

    <v-divider />

    <v-sheet class="px-4 mt-4">
      <base-button
        text="Выход"
        @click="handleLogout"
      />
    </v-sheet>
  </v-navigation-drawer>
</template>

<style scoped>

</style>
