<template>
  <base-layout :is-loading="isLoading">
    <template #header>
      <CartHeader />
    </template>
    <v-sheet
      class="ma-0 pa-0 w-100 mx-auto"
      style="max-width: 1160px"
    >
      <v-sheet class="w-100 h-100 pt-13 pb-4">
        <cart-main />
      </v-sheet>
    </v-sheet>
  </base-layout>
</template>

<script setup>
import CartMain from "@/components/Cart/CartMain.vue";
import CartHeader from "@/components/Header/CartHeader.vue";
import BaseLayout from "@/components/Layouts/BaseLayout.vue";
import {useMenuStore} from "@/store/MenuStore";
import {useAuthStore} from "@/store/AuthStore";
import {storeToRefs} from "pinia";
import {computed, onMounted, ref} from "vue";
import {useCartStore} from "@/store/CartStore";

const menuStore = useMenuStore();
const authStore = useAuthStore();
const cartStore = useCartStore();
const { isAuthenticated } = storeToRefs(authStore);
const isLoaded = ref(false);
const isLoading = computed(
    () => menuStore.isLoadingSections || menuStore.isLoadingCities || authStore.isLoading || !isAuthenticated.value
);

onMounted(async () => {
  if (!isLoaded.value) {
    try {
      await authStore.checkAuth();
      await menuStore.getMenuSections(menuStore.currentCity.id)
      if (!isAuthenticated.value && !authStore.isLoading) {
        authStore.openLoginModal()
      }
      if (isAuthenticated.value) {
        await cartStore.getCart();
      }

      // Устанавливаем флаг, чтобы запросы не выполнялись снова
      isLoaded.value = true;
    } catch (error) {
      console.error(error);
    }
  }
});
</script>
