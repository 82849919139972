<script setup>
  import FooterMain from "@/components/Footer/FooterMain.vue";
  import {nextTick, onMounted, onUnmounted, ref} from "vue";

  defineProps({
    isLoading: {
      type: Boolean,
      default: false,
    }
  })
  const parentRef = ref(null);
  const parentHeight = ref(0);
  let resizeObserver = null;

  onMounted(() => {
    if (parentRef.value) {
      resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          parentHeight.value = entry.contentRect.height; // Устанавливаем высоту родителя
        }
      });
      resizeObserver.observe(parentRef.value);
    }
  });

  onUnmounted(() => {
    if (resizeObserver) {
      resizeObserver.disconnect();
    }
  });
</script>

<template>
  <v-container
    v-if="isLoading"
    class="fill-height pa-0"
    fluid
  >
    <v-skeleton-loader
      color="Secondary"
      type="image"
      class="w-100 h-100 fullscreen-skeleton"
    />
  </v-container>
  <v-sheet
    v-else
    class="pa-0 ma-0"
  >
    <v-sheet
      v-if="!isLoading"
      ref="headerRef"
      class="w-100 top-0 left-0 border-b bg-white container position-sticky"
      style="z-index: 1000; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
    >
      <slot name="header" />
    </v-sheet>
    <v-sheet
      class="d-flex justify-center mx-auto px-4"
      max-width="1432"
    >
      <slot />
    </v-sheet>
    <footer-main />
  </v-sheet>
</template>

<style scoped>

</style>
