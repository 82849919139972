<script setup>

</script>

<template>
  <v-dialog
    max-width="none"
    fullscreen
    close-on-content-click
  >
    <template #activator="{ props: activatorProps }">
      <v-sheet
        v-bind="activatorProps"
        class="text-white text-decoration-underline pa-0 ma-0 bg-transparent cursor-pointer"
      >
        Условия доставки и возврата
      </v-sheet>
    </template>

    <template #default="{ isActive }">
      <v-card>
        <template #append>
          <v-icon
            class="cursor-pointer"
            color="black"
            icon="mdi-close"
            @click="isActive.value = false"
          />
        </template>
        <v-card-text>
          <v-sheet
            class="d-flex w-100 h-100 justify-center align-center flex-column mx-auto"
            max-width="1400"
          >
            <v-sheet class="text-h6 semibold w-100">
              Условия доставки:
            </v-sheet>
            <v-list>
              <v-list-item>1 доставка осуществляется обособленной курьерской службой; </v-list-item>
              <v-list-item>2. доставка по городу осуществляется бесплатно, однако существует минимальная сумма заказа для каждого района. Стоимость доставки сообщается оператором до осуществления заказа или отражается в разделе «Корзина» на сайте Компании;</v-list-item>
              <v-list-item>3. минимальная сумма доставки – 799 рублей.</v-list-item>
            </v-list>

            <v-sheet class="text-h6 semibold w-100">
              Условия возврата:
            </v-sheet>
            <v-list>
              <v-list-item>1. Блюдо надлежащего качества возврату не подлежит;</v-list-item>
              <v-list-item>2. Денежные средства за блюдо надлежащего качества возврату не подлежат;</v-list-item>
              <v-list-item>
                3. Отказ от заказа возможен в первые 10 минут после оформления заказа. Далее отказ не
                возможен и возврат денежных средств не осуществляется;
              </v-list-item>
              <v-list-item>
                4. При получении блюда ненадлежащего качества необходимо в течение 14 дней обратиться
                по номеру +7 938 878-00-44, либо написать претензию на почту sochi@chief-project.com с
                полным описанием проблемы;
              </v-list-item>
              <v-list-item>5. Срок рассмотрения претензии 1 рабочий день;</v-list-item>
              <v-list-item>
                6. Возврат средств осуществляется только в случаях продажи блюда ненадлежащего
                качества.
              </v-list-item>
            </v-list>
          </v-sheet>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<style scoped>

</style>
