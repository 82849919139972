<script setup>
import {ref, useAttrs} from "vue";
import {Field} from "vee-validate";

  const props = defineProps({
    modelValue: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    errorMessage: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    }
  })

  const emit = defineEmits(['update:modelValue']); // используем `update:modelValue` для передачи значений обратно в родительский компонент
  const attrs = useAttrs();
  const isFieldDisabled = ref(true);
  const handleInput = (event) => {
    emit('update:modelValue', event.target.value); // обновляем значение `modelValue` при изменении
  };
</script>

<template>
  <v-sheet class="d-flex flex-row justify-space-between align-end elevation-1 px-4 py-3 w-100">
    <v-sheet>
      <v-sheet class="text-grey-darken-2 mb-1">
        {{ props.title }}
      </v-sheet>
      <v-sheet class="text-h6">
        <Field
          v-bind="attrs"
          :id="props.title"
          :class="{'border-b-md border-customRed enabled': !isFieldDisabled, 'border-error': errorMessage}"
          validate-on-input
          placeholder="-"
          style="all: unset"
          type="text"
          :name="props.name"
          :value="props.modelValue"
          :disabled="isFieldDisabled"
          @input="handleInput"
        />
      </v-sheet>
      <span
        v-if="errorMessage"
        class="error-message"
      >{{ errorMessage }}</span>
    </v-sheet>
    <v-sheet
      class="cursor-pointer"
      @click="isFieldDisabled = !isFieldDisabled"
    >
      <v-img
        width="20"
        height="20"
        src="@/assets/red_pen_icon.svg"
      />
    </v-sheet>
  </v-sheet>
</template>

<style scoped>
.error-message {
  color: red;
  font-size: 0.8em;
}
.enabled {
  border-bottom: 2px solid #E31517 !important;
}
</style>
