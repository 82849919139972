import { parse, isEqual } from 'date-fns';
import RubleIcon from "@/components/base/currency/RubleIcon.vue";
import SomIcon from "@/components/base/currency/SomIcon.vue";
import { fromZonedTime } from 'date-fns-tz';
export const log = (value) => {
    console.log(JSON.parse(JSON.stringify(value)));
}

export const isDatesEqual = (date1, format1, date2, format2) => {
    // Преобразуем первую дату с использованием первого формата
    const parsedDate1 = parse(date1, format1, new Date());

    // Преобразуем вторую дату с использованием второго формата
    const parsedDate2 = parse(date2, format2, new Date());

    // Сравниваем даты с использованием isEqual
    return isEqual(parsedDate1, parsedDate2);
}

export const combineDateTime = (timeString, dateObject, timezone) => {
    if (!timeString || !dateObject) return null;
    // Разбиваем время на часы и минуты
    const [hours, minutes] = timeString.split(':').map(Number);

    // Устанавливаем часы и минуты в объекте даты
    dateObject.setHours(hours);
    dateObject.setMinutes(minutes);
    dateObject.setSeconds(0);  // Секунды обнуляем

    const timezoneDate = fromZonedTime(dateObject, timezone);

    // Преобразуем в формат ISO 8601 (UTC)
    return timezoneDate.toISOString();
}

export const generateRandomString = (length) =>  {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export const removeNonNumeric = (str) => {
    return str.replace(/\D/g, ''); // \D соответствует всем символам, кроме цифр
}

export const cleanLink = (link) =>  {
    return link.replace(/^(tel:|mailto:)/, "");
}

export const formatPhoneNumber = (phone) => {
    // Убедимся, что номер содержит только цифры, удалив все лишние символы
    const cleanedPhone = phone.replace(/[^\d]/g, "");

    // Определяем код страны: +7 или +99
    if (cleanedPhone.startsWith("7")) {
        return cleanedPhone.replace(
            /^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/,
            "+$1 ($2) $3-$4-$5"
        );
    } else if (cleanedPhone.startsWith("99")) {
        return cleanedPhone.replace(
            /^(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})$/,
            "+$1 ($2) $3-$4-$5"
        );
    } else {
        // Возвращаем номер как есть, если он не начинается с 7 или 99
        return phone;
    }
};


export const formatDate = (dateString)=> {
    const date = new Date(dateString);

    const formatter = new Intl.DateTimeFormat('ru-RU', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    });

    return formatter.format(date);
}

export const formatPrice = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export const getCurrencySymbol = (city) => {
    if (!city || !city.currency || !city.currency.code) return '₽';
    switch (city.currency.code) {
        case 'RUB':
            return '₽';
        case 'UZS':
            return '⃀';
    }
}

export const getCurrencyIcon = (city) => {
    if (!city || !city.currency || !city.currency.code) return RubleIcon;
    switch (city.currency.code) {
        case 'RUB':
            return RubleIcon;
        case 'UZS':
            return SomIcon;
        default:
            return RubleIcon; // По умолчанию рубль
    }
};
