export const mockSections = [
  {
    id: "1",
    title: "Appetizers",
    priority: 1,
    scope: "dine-in",
    products: [
      {
        id: "101",
        title: "Spring Rolls",
        short_description: "Crispy rolls stuffed with vegetables.",
        description:
          "Delicious crispy rolls filled with a mix of fresh vegetables and served with a sweet chili sauce.",
        price: 500,
        priority: 1,
        meta_weight: "200",
        meta_weight_unit: 1,
        meta_calories: 300,
        meta_proteins: 5,
        meta_fat: 10,
        meta_carbohydrates: 40,
        image_full_url: "https://example.com/images/spring_rolls.jpg",
        image_small_url: "https://example.com/images/spring_rolls_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/spring_rolls_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "102",
        title: "Edamame",
        short_description: "Steamed soybeans with sea salt.",
        description:
          "Fresh steamed edamame beans sprinkled with sea salt. A light and healthy start to your meal.",
        price: 400,
        priority: 2,
        meta_weight: "150",
        meta_weight_unit: 1,
        meta_calories: 200,
        meta_proteins: 12,
        meta_fat: 7,
        meta_carbohydrates: 10,
        image_full_url: "https://example.com/images/edamame.jpg",
        image_small_url: "https://example.com/images/edamame_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/edamame_catalog.jpg",
        modifiers: [
          {
            id: "mod1",
            title: "Seasoning",
            title_form: "Choose your seasoning",
            items: [
              {
                id: "mod_item1",
                title: "Extra Sea Salt",
                price: 50,
              },
              {
                id: "mod_item2",
                title: "Spicy Chili Powder",
                price: 70,
              },
            ],
          },
          {
            id: "mod2",
            title: "Title",
            title_form: "Выберите соус",
            items: [
              {
                id: "mod_item1",
                title: "Кислый",
                price: 50,
              },
              {
                id: "mod_item2",
                title: "Сладкий",
                price: 70,
              },
            ],
          },
        ],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "103",
        title: "Chicken Satay",
        short_description: "Grilled chicken skewers with peanut sauce.",
        description:
          "Tender grilled chicken skewers served with a rich peanut dipping sauce.",
        price: 700,
        priority: 3,
        meta_weight: "250",
        meta_weight_unit: 1,
        meta_calories: 500,
        meta_proteins: 25,
        meta_fat: 25,
        meta_carbohydrates: 40,
        image_full_url: "https://example.com/images/chicken_satay.jpg",
        image_small_url: "https://example.com/images/chicken_satay_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/chicken_satay_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
    ],
    active: true,
  },
  {
    id: "2",
    title: "Main Courses",
    priority: 2,
    scope: "dine-in",
    products: [
      {
        id: "201",
        title: "Pad Thai",
        short_description: "Stir-fried noodles with shrimp and tofu.",
        description:
          "Classic Thai stir-fried noodles with shrimp, tofu, peanuts, and bean sprouts.",
        price: 1200,
        priority: 1,
        meta_weight: "350",
        meta_weight_unit: 1,
        meta_calories: 800,
        meta_proteins: 30,
        meta_fat: 35,
        meta_carbohydrates: 90,
        image_full_url: "https://example.com/images/pad_thai.jpg",
        image_small_url: "https://example.com/images/pad_thai_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/pad_thai_catalog.jpg",
        modifiers: [
          {
            id: "mod2",
            title: "Protein Choice",
            title_form: "Choose your protein",
            items: [
              {
                id: "mod_item3",
                title: "Extra Shrimp",
                price: 200,
              },
              {
                id: "mod_item4",
                title: "Chicken",
                price: 150,
              },
            ],
          },
        ],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "202",
        title: "Green Curry",
        short_description: "Spicy green curry with chicken and vegetables.",
        description:
          "Aromatic Thai green curry with chicken, bamboo shoots, and bell peppers.",
        price: 1300,
        priority: 2,
        meta_weight: "400",
        meta_weight_unit: 1,
        meta_calories: 750,
        meta_proteins: 28,
        meta_fat: 40,
        meta_carbohydrates: 70,
        image_full_url: "https://example.com/images/green_curry.jpg",
        image_small_url: "https://example.com/images/green_curry_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/green_curry_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "203",
        title: "Beef Bulgogi",
        short_description: "Korean grilled beef with rice.",
        description:
          "Tender marinated beef grilled to perfection, served with steamed rice.",
        price: 1400,
        priority: 3,
        meta_weight: "350",
        meta_weight_unit: 1,
        meta_calories: 850,
        meta_proteins: 35,
        meta_fat: 45,
        meta_carbohydrates: 80,
        image_full_url: "https://example.com/images/beef_bulgogi.jpg",
        image_small_url: "https://example.com/images/beef_bulgogi_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/beef_bulgogi_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
    ],
    active: true,
  },
  {
    id: "3",
    title: "Main Courses222",
    priority: 2,
    scope: "dine-in",
    products: [
      {
        id: "201",
        title: "Pad Thai",
        short_description: "Stir-fried noodles with shrimp and tofu.",
        description:
          "Classic Thai stir-fried noodles with shrimp, tofu, peanuts, and bean sprouts.",
        price: 1200,
        priority: 1,
        meta_weight: "350",
        meta_weight_unit: 1,
        meta_calories: 800,
        meta_proteins: 30,
        meta_fat: 35,
        meta_carbohydrates: 90,
        image_full_url: "https://example.com/images/pad_thai.jpg",
        image_small_url: "https://example.com/images/pad_thai_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/pad_thai_catalog.jpg",
        modifiers: [
          {
            id: "mod2",
            title: "Protein Choice",
            title_form: "Choose your protein",
            items: [
              {
                id: "mod_item3",
                title: "Extra Shrimp",
                price: 200,
              },
              {
                id: "mod_item4",
                title: "Chicken",
                price: 150,
              },
            ],
          },
        ],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "202",
        title: "Green Curry",
        short_description: "Spicy green curry with chicken and vegetables.",
        description:
          "Aromatic Thai green curry with chicken, bamboo shoots, and bell peppers.",
        price: 1300,
        priority: 2,
        meta_weight: "400",
        meta_weight_unit: 1,
        meta_calories: 750,
        meta_proteins: 28,
        meta_fat: 40,
        meta_carbohydrates: 70,
        image_full_url: "https://example.com/images/green_curry.jpg",
        image_small_url: "https://example.com/images/green_curry_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/green_curry_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "203",
        title: "Beef Bulgogi",
        short_description: "Korean grilled beef with rice.",
        description:
          "Tender marinated beef grilled to perfection, served with steamed rice.",
        price: 1400,
        priority: 3,
        meta_weight: "350",
        meta_weight_unit: 1,
        meta_calories: 850,
        meta_proteins: 35,
        meta_fat: 45,
        meta_carbohydrates: 80,
        image_full_url: "https://example.com/images/beef_bulgogi.jpg",
        image_small_url: "https://example.com/images/beef_bulgogi_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/beef_bulgogi_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
    ],
    active: true,
  },
  {
    id: "4",
    title: "Appetizers",
    priority: 1,
    scope: "dine-in",
    products: [
      {
        id: "101",
        title: "Spring Rolls",
        short_description: "Crispy rolls stuffed with vegetables.",
        description:
          "Delicious crispy rolls filled with a mix of fresh vegetables and served with a sweet chili sauce.",
        price: 500,
        priority: 1,
        meta_weight: "200",
        meta_weight_unit: 1,
        meta_calories: 300,
        meta_proteins: 5,
        meta_fat: 10,
        meta_carbohydrates: 40,
        image_full_url: "https://example.com/images/spring_rolls.jpg",
        image_small_url: "https://example.com/images/spring_rolls_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/spring_rolls_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "102",
        title: "Edamame",
        short_description: "Steamed soybeans with sea salt.",
        description:
          "Fresh steamed edamame beans sprinkled with sea salt. A light and healthy start to your meal.",
        price: 400,
        priority: 2,
        meta_weight: "150",
        meta_weight_unit: 1,
        meta_calories: 200,
        meta_proteins: 12,
        meta_fat: 7,
        meta_carbohydrates: 10,
        image_full_url: "https://example.com/images/edamame.jpg",
        image_small_url: "https://example.com/images/edamame_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/edamame_catalog.jpg",
        modifiers: [
          {
            id: "mod1",
            title: "Seasoning",
            title_form: "Choose your seasoning",
            items: [
              {
                id: "mod_item1",
                title: "Extra Sea Salt",
                price: 50,
              },
              {
                id: "mod_item2",
                title: "Spicy Chili Powder",
                price: 70,
              },
            ],
          },
          {
            id: "mod2",
            title: "Title",
            title_form: "Выберите соус",
            items: [
              {
                id: "mod_item1",
                title: "Кислый",
                price: 50,
              },
              {
                id: "mod_item2",
                title: "Сладкий",
                price: 70,
              },
            ],
          },
        ],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "103",
        title: "Chicken Satay",
        short_description: "Grilled chicken skewers with peanut sauce.",
        description:
          "Tender grilled chicken skewers served with a rich peanut dipping sauce.",
        price: 700,
        priority: 3,
        meta_weight: "250",
        meta_weight_unit: 1,
        meta_calories: 500,
        meta_proteins: 25,
        meta_fat: 25,
        meta_carbohydrates: 40,
        image_full_url: "https://example.com/images/chicken_satay.jpg",
        image_small_url: "https://example.com/images/chicken_satay_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/chicken_satay_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
    ],
    active: true,
  },
  {
    id: "5",
    title: "Main Courses",
    priority: 2,
    scope: "dine-in",
    products: [
      {
        id: "201",
        title: "Pad Thai",
        short_description: "Stir-fried noodles with shrimp and tofu.",
        description:
          "Classic Thai stir-fried noodles with shrimp, tofu, peanuts, and bean sprouts.",
        price: 1200,
        priority: 1,
        meta_weight: "350",
        meta_weight_unit: 1,
        meta_calories: 800,
        meta_proteins: 30,
        meta_fat: 35,
        meta_carbohydrates: 90,
        image_full_url: "https://example.com/images/pad_thai.jpg",
        image_small_url: "https://example.com/images/pad_thai_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/pad_thai_catalog.jpg",
        modifiers: [
          {
            id: "mod2",
            title: "Protein Choice",
            title_form: "Choose your protein",
            items: [
              {
                id: "mod_item3",
                title: "Extra Shrimp",
                price: 200,
              },
              {
                id: "mod_item4",
                title: "Chicken",
                price: 150,
              },
            ],
          },
        ],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "202",
        title: "Green Curry",
        short_description: "Spicy green curry with chicken and vegetables.",
        description:
          "Aromatic Thai green curry with chicken, bamboo shoots, and bell peppers.",
        price: 1300,
        priority: 2,
        meta_weight: "400",
        meta_weight_unit: 1,
        meta_calories: 750,
        meta_proteins: 28,
        meta_fat: 40,
        meta_carbohydrates: 70,
        image_full_url: "https://example.com/images/green_curry.jpg",
        image_small_url: "https://example.com/images/green_curry_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/green_curry_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "203",
        title: "Beef Bulgogi",
        short_description: "Korean grilled beef with rice.",
        description:
          "Tender marinated beef grilled to perfection, served with steamed rice.",
        price: 1400,
        priority: 3,
        meta_weight: "350",
        meta_weight_unit: 1,
        meta_calories: 850,
        meta_proteins: 35,
        meta_fat: 45,
        meta_carbohydrates: 80,
        image_full_url: "https://example.com/images/beef_bulgogi.jpg",
        image_small_url: "https://example.com/images/beef_bulgogi_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/beef_bulgogi_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
    ],
    active: true,
  },
  {
    id: "6",
    title: "Main Courses222",
    priority: 2,
    scope: "dine-in",
    products: [
      {
        id: "201",
        title: "Pad Thai",
        short_description: "Stir-fried noodles with shrimp and tofu.",
        description:
          "Classic Thai stir-fried noodles with shrimp, tofu, peanuts, and bean sprouts.",
        price: 1200,
        priority: 1,
        meta_weight: "350",
        meta_weight_unit: 1,
        meta_calories: 800,
        meta_proteins: 30,
        meta_fat: 35,
        meta_carbohydrates: 90,
        image_full_url: "https://example.com/images/pad_thai.jpg",
        image_small_url: "https://example.com/images/pad_thai_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/pad_thai_catalog.jpg",
        modifiers: [
          {
            id: "mod2",
            title: "Protein Choice",
            title_form: "Choose your protein",
            items: [
              {
                id: "mod_item3",
                title: "Extra Shrimp",
                price: 200,
              },
              {
                id: "mod_item4",
                title: "Chicken",
                price: 150,
              },
            ],
          },
        ],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "202",
        title: "Green Curry",
        short_description: "Spicy green curry with chicken and vegetables.",
        description:
          "Aromatic Thai green curry with chicken, bamboo shoots, and bell peppers.",
        price: 1300,
        priority: 2,
        meta_weight: "400",
        meta_weight_unit: 1,
        meta_calories: 750,
        meta_proteins: 28,
        meta_fat: 40,
        meta_carbohydrates: 70,
        image_full_url: "https://example.com/images/green_curry.jpg",
        image_small_url: "https://example.com/images/green_curry_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/green_curry_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "203",
        title: "Beef Bulgogi",
        short_description: "Korean grilled beef with rice.",
        description:
          "Tender marinated beef grilled to perfection, served with steamed rice.",
        price: 1400,
        priority: 3,
        meta_weight: "350",
        meta_weight_unit: 1,
        meta_calories: 850,
        meta_proteins: 35,
        meta_fat: 45,
        meta_carbohydrates: 80,
        image_full_url: "https://example.com/images/beef_bulgogi.jpg",
        image_small_url: "https://example.com/images/beef_bulgogi_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/beef_bulgogi_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
    ],
    active: true,
  },
  {
    id: "7",
    title: "Appetizers",
    priority: 1,
    scope: "dine-in",
    products: [
      {
        id: "101",
        title: "Spring Rolls",
        short_description: "Crispy rolls stuffed with vegetables.",
        description:
          "Delicious crispy rolls filled with a mix of fresh vegetables and served with a sweet chili sauce.",
        price: 500,
        priority: 1,
        meta_weight: "200",
        meta_weight_unit: 1,
        meta_calories: 300,
        meta_proteins: 5,
        meta_fat: 10,
        meta_carbohydrates: 40,
        image_full_url: "https://example.com/images/spring_rolls.jpg",
        image_small_url: "https://example.com/images/spring_rolls_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/spring_rolls_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "102",
        title: "Edamame",
        short_description: "Steamed soybeans with sea salt.",
        description:
          "Fresh steamed edamame beans sprinkled with sea salt. A light and healthy start to your meal.",
        price: 400,
        priority: 2,
        meta_weight: "150",
        meta_weight_unit: 1,
        meta_calories: 200,
        meta_proteins: 12,
        meta_fat: 7,
        meta_carbohydrates: 10,
        image_full_url: "https://example.com/images/edamame.jpg",
        image_small_url: "https://example.com/images/edamame_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/edamame_catalog.jpg",
        modifiers: [
          {
            id: "mod1",
            title: "Seasoning",
            title_form: "Choose your seasoning",
            items: [
              {
                id: "mod_item1",
                title: "Extra Sea Salt",
                price: 50,
              },
              {
                id: "mod_item2",
                title: "Spicy Chili Powder",
                price: 70,
              },
            ],
          },
          {
            id: "mod2",
            title: "Title",
            title_form: "Выберите соус",
            items: [
              {
                id: "mod_item1",
                title: "Кислый",
                price: 50,
              },
              {
                id: "mod_item2",
                title: "Сладкий",
                price: 70,
              },
            ],
          },
        ],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "103",
        title: "Chicken Satay",
        short_description: "Grilled chicken skewers with peanut sauce.",
        description:
          "Tender grilled chicken skewers served with a rich peanut dipping sauce.",
        price: 700,
        priority: 3,
        meta_weight: "250",
        meta_weight_unit: 1,
        meta_calories: 500,
        meta_proteins: 25,
        meta_fat: 25,
        meta_carbohydrates: 40,
        image_full_url: "https://example.com/images/chicken_satay.jpg",
        image_small_url: "https://example.com/images/chicken_satay_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/chicken_satay_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
    ],
    active: true,
  },
  {
    id: "8",
    title: "Main Courses",
    priority: 2,
    scope: "dine-in",
    products: [
      {
        id: "201",
        title: "Pad Thai",
        short_description: "Stir-fried noodles with shrimp and tofu.",
        description:
          "Classic Thai stir-fried noodles with shrimp, tofu, peanuts, and bean sprouts.",
        price: 1200,
        priority: 1,
        meta_weight: "350",
        meta_weight_unit: 1,
        meta_calories: 800,
        meta_proteins: 30,
        meta_fat: 35,
        meta_carbohydrates: 90,
        image_full_url: "https://example.com/images/pad_thai.jpg",
        image_small_url: "https://example.com/images/pad_thai_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/pad_thai_catalog.jpg",
        modifiers: [
          {
            id: "mod2",
            title: "Protein Choice",
            title_form: "Choose your protein",
            items: [
              {
                id: "mod_item3",
                title: "Extra Shrimp",
                price: 200,
              },
              {
                id: "mod_item4",
                title: "Chicken",
                price: 150,
              },
            ],
          },
        ],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "202",
        title: "Green Curry",
        short_description: "Spicy green curry with chicken and vegetables.",
        description:
          "Aromatic Thai green curry with chicken, bamboo shoots, and bell peppers.",
        price: 1300,
        priority: 2,
        meta_weight: "400",
        meta_weight_unit: 1,
        meta_calories: 750,
        meta_proteins: 28,
        meta_fat: 40,
        meta_carbohydrates: 70,
        image_full_url: "https://example.com/images/green_curry.jpg",
        image_small_url: "https://example.com/images/green_curry_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/green_curry_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "203",
        title: "Beef Bulgogi",
        short_description: "Korean grilled beef with rice.",
        description:
          "Tender marinated beef grilled to perfection, served with steamed rice.",
        price: 1400,
        priority: 3,
        meta_weight: "350",
        meta_weight_unit: 1,
        meta_calories: 850,
        meta_proteins: 35,
        meta_fat: 45,
        meta_carbohydrates: 80,
        image_full_url: "https://example.com/images/beef_bulgogi.jpg",
        image_small_url: "https://example.com/images/beef_bulgogi_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/beef_bulgogi_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
    ],
    active: true,
  },
  {
    id: "9",
    title: "Main Courses222",
    priority: 2,
    scope: "dine-in",
    products: [
      {
        id: "201",
        title: "Pad Thai",
        short_description: "Stir-fried noodles with shrimp and tofu.",
        description:
          "Classic Thai stir-fried noodles with shrimp, tofu, peanuts, and bean sprouts.",
        price: 1200,
        priority: 1,
        meta_weight: "350",
        meta_weight_unit: 1,
        meta_calories: 800,
        meta_proteins: 30,
        meta_fat: 35,
        meta_carbohydrates: 90,
        image_full_url: "https://example.com/images/pad_thai.jpg",
        image_small_url: "https://example.com/images/pad_thai_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/pad_thai_catalog.jpg",
        modifiers: [
          {
            id: "mod2",
            title: "Protein Choice",
            title_form: "Choose your protein",
            items: [
              {
                id: "mod_item3",
                title: "Extra Shrimp",
                price: 200,
              },
              {
                id: "mod_item4",
                title: "Chicken",
                price: 150,
              },
            ],
          },
        ],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "202",
        title: "Green Curry",
        short_description: "Spicy green curry with chicken and vegetables.",
        description:
          "Aromatic Thai green curry with chicken, bamboo shoots, and bell peppers.",
        price: 1300,
        priority: 2,
        meta_weight: "400",
        meta_weight_unit: 1,
        meta_calories: 750,
        meta_proteins: 28,
        meta_fat: 40,
        meta_carbohydrates: 70,
        image_full_url: "https://example.com/images/green_curry.jpg",
        image_small_url: "https://example.com/images/green_curry_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/green_curry_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "203",
        title: "Beef Bulgogi",
        short_description: "Korean grilled beef with rice.",
        description:
          "Tender marinated beef grilled to perfection, served with steamed rice.",
        price: 1400,
        priority: 3,
        meta_weight: "350",
        meta_weight_unit: 1,
        meta_calories: 850,
        meta_proteins: 35,
        meta_fat: 45,
        meta_carbohydrates: 80,
        image_full_url: "https://example.com/images/beef_bulgogi.jpg",
        image_small_url: "https://example.com/images/beef_bulgogi_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/beef_bulgogi_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
    ],
    active: true,
  },
  {
    id: "10",
    title: "Appetizers",
    priority: 1,
    scope: "dine-in",
    products: [
      {
        id: "101",
        title: "Spring Rolls",
        short_description: "Crispy rolls stuffed with vegetables.",
        description:
          "Delicious crispy rolls filled with a mix of fresh vegetables and served with a sweet chili sauce.",
        price: 500,
        priority: 1,
        meta_weight: "200",
        meta_weight_unit: 1,
        meta_calories: 300,
        meta_proteins: 5,
        meta_fat: 10,
        meta_carbohydrates: 40,
        image_full_url: "https://example.com/images/spring_rolls.jpg",
        image_small_url: "https://example.com/images/spring_rolls_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/spring_rolls_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "102",
        title: "Edamame",
        short_description: "Steamed soybeans with sea salt.",
        description:
          "Fresh steamed edamame beans sprinkled with sea salt. A light and healthy start to your meal.",
        price: 400,
        priority: 2,
        meta_weight: "150",
        meta_weight_unit: 1,
        meta_calories: 200,
        meta_proteins: 12,
        meta_fat: 7,
        meta_carbohydrates: 10,
        image_full_url: "https://example.com/images/edamame.jpg",
        image_small_url: "https://example.com/images/edamame_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/edamame_catalog.jpg",
        modifiers: [
          {
            id: "mod1",
            title: "Seasoning",
            title_form: "Choose your seasoning",
            items: [
              {
                id: "mod_item1",
                title: "Extra Sea Salt",
                price: 50,
              },
              {
                id: "mod_item2",
                title: "Spicy Chili Powder",
                price: 70,
              },
            ],
          },
          {
            id: "mod2",
            title: "Title",
            title_form: "Выберите соус",
            items: [
              {
                id: "mod_item1",
                title: "Кислый",
                price: 50,
              },
              {
                id: "mod_item2",
                title: "Сладкий",
                price: 70,
              },
            ],
          },
        ],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "103",
        title: "Chicken Satay",
        short_description: "Grilled chicken skewers with peanut sauce.",
        description:
          "Tender grilled chicken skewers served with a rich peanut dipping sauce.",
        price: 700,
        priority: 3,
        meta_weight: "250",
        meta_weight_unit: 1,
        meta_calories: 500,
        meta_proteins: 25,
        meta_fat: 25,
        meta_carbohydrates: 40,
        image_full_url: "https://example.com/images/chicken_satay.jpg",
        image_small_url: "https://example.com/images/chicken_satay_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/chicken_satay_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
    ],
    active: true,
  },
  {
    id: "11",
    title: "Main Courses",
    priority: 2,
    scope: "dine-in",
    products: [
      {
        id: "201",
        title: "Pad Thai",
        short_description: "Stir-fried noodles with shrimp and tofu.",
        description:
          "Classic Thai stir-fried noodles with shrimp, tofu, peanuts, and bean sprouts.",
        price: 1200,
        priority: 1,
        meta_weight: "350",
        meta_weight_unit: 1,
        meta_calories: 800,
        meta_proteins: 30,
        meta_fat: 35,
        meta_carbohydrates: 90,
        image_full_url: "https://example.com/images/pad_thai.jpg",
        image_small_url: "https://example.com/images/pad_thai_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/pad_thai_catalog.jpg",
        modifiers: [
          {
            id: "mod2",
            title: "Protein Choice",
            title_form: "Choose your protein",
            items: [
              {
                id: "mod_item3",
                title: "Extra Shrimp",
                price: 200,
              },
              {
                id: "mod_item4",
                title: "Chicken",
                price: 150,
              },
            ],
          },
        ],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "202",
        title: "Green Curry",
        short_description: "Spicy green curry with chicken and vegetables.",
        description:
          "Aromatic Thai green curry with chicken, bamboo shoots, and bell peppers.",
        price: 1300,
        priority: 2,
        meta_weight: "400",
        meta_weight_unit: 1,
        meta_calories: 750,
        meta_proteins: 28,
        meta_fat: 40,
        meta_carbohydrates: 70,
        image_full_url: "https://example.com/images/green_curry.jpg",
        image_small_url: "https://example.com/images/green_curry_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/green_curry_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "203",
        title: "Beef Bulgogi",
        short_description: "Korean grilled beef with rice.",
        description:
          "Tender marinated beef grilled to perfection, served with steamed rice.",
        price: 1400,
        priority: 3,
        meta_weight: "350",
        meta_weight_unit: 1,
        meta_calories: 850,
        meta_proteins: 35,
        meta_fat: 45,
        meta_carbohydrates: 80,
        image_full_url: "https://example.com/images/beef_bulgogi.jpg",
        image_small_url: "https://example.com/images/beef_bulgogi_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/beef_bulgogi_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
    ],
    active: true,
  },
  {
    id: "12",
    title: "Main Courses222",
    priority: 2,
    scope: "dine-in",
    products: [
      {
        id: "201",
        title: "Pad Thai",
        short_description: "Stir-fried noodles with shrimp and tofu.",
        description:
          "Classic Thai stir-fried noodles with shrimp, tofu, peanuts, and bean sprouts.",
        price: 1200,
        priority: 1,
        meta_weight: "350",
        meta_weight_unit: 1,
        meta_calories: 800,
        meta_proteins: 30,
        meta_fat: 35,
        meta_carbohydrates: 90,
        image_full_url: "https://example.com/images/pad_thai.jpg",
        image_small_url: "https://example.com/images/pad_thai_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/pad_thai_catalog.jpg",
        modifiers: [
          {
            id: "mod2",
            title: "Protein Choice",
            title_form: "Choose your protein",
            items: [
              {
                id: "mod_item3",
                title: "Extra Shrimp",
                price: 200,
              },
              {
                id: "mod_item4",
                title: "Chicken",
                price: 150,
              },
            ],
          },
        ],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "202",
        title: "Green Curry",
        short_description: "Spicy green curry with chicken and vegetables.",
        description:
          "Aromatic Thai green curry with chicken, bamboo shoots, and bell peppers.",
        price: 1300,
        priority: 2,
        meta_weight: "400",
        meta_weight_unit: 1,
        meta_calories: 750,
        meta_proteins: 28,
        meta_fat: 40,
        meta_carbohydrates: 70,
        image_full_url: "https://example.com/images/green_curry.jpg",
        image_small_url: "https://example.com/images/green_curry_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/green_curry_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "203",
        title: "Beef Bulgogi",
        short_description: "Korean grilled beef with rice.",
        description:
          "Tender marinated beef grilled to perfection, served with steamed rice.",
        price: 1400,
        priority: 3,
        meta_weight: "350",
        meta_weight_unit: 1,
        meta_calories: 850,
        meta_proteins: 35,
        meta_fat: 45,
        meta_carbohydrates: 80,
        image_full_url: "https://example.com/images/beef_bulgogi.jpg",
        image_small_url: "https://example.com/images/beef_bulgogi_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/beef_bulgogi_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
    ],
    active: true,
  },
  {
    id: "13",
    title: "Appetizers",
    priority: 1,
    scope: "dine-in",
    products: [
      {
        id: "101",
        title: "Spring Rolls",
        short_description: "Crispy rolls stuffed with vegetables.",
        description:
          "Delicious crispy rolls filled with a mix of fresh vegetables and served with a sweet chili sauce.",
        price: 500,
        priority: 1,
        meta_weight: "200",
        meta_weight_unit: 1,
        meta_calories: 300,
        meta_proteins: 5,
        meta_fat: 10,
        meta_carbohydrates: 40,
        image_full_url: "https://example.com/images/spring_rolls.jpg",
        image_small_url: "https://example.com/images/spring_rolls_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/spring_rolls_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "102",
        title: "Edamame",
        short_description: "Steamed soybeans with sea salt.",
        description:
          "Fresh steamed edamame beans sprinkled with sea salt. A light and healthy start to your meal.",
        price: 400,
        priority: 2,
        meta_weight: "150",
        meta_weight_unit: 1,
        meta_calories: 200,
        meta_proteins: 12,
        meta_fat: 7,
        meta_carbohydrates: 10,
        image_full_url: "https://example.com/images/edamame.jpg",
        image_small_url: "https://example.com/images/edamame_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/edamame_catalog.jpg",
        modifiers: [
          {
            id: "mod1",
            title: "Seasoning",
            title_form: "Choose your seasoning",
            items: [
              {
                id: "mod_item1",
                title: "Extra Sea Salt",
                price: 50,
              },
              {
                id: "mod_item2",
                title: "Spicy Chili Powder",
                price: 70,
              },
            ],
          },
          {
            id: "mod2",
            title: "Title",
            title_form: "Выберите соус",
            items: [
              {
                id: "mod_item1",
                title: "Кислый",
                price: 50,
              },
              {
                id: "mod_item2",
                title: "Сладкий",
                price: 70,
              },
            ],
          },
        ],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "103",
        title: "Chicken Satay",
        short_description: "Grilled chicken skewers with peanut sauce.",
        description:
          "Tender grilled chicken skewers served with a rich peanut dipping sauce.",
        price: 700,
        priority: 3,
        meta_weight: "250",
        meta_weight_unit: 1,
        meta_calories: 500,
        meta_proteins: 25,
        meta_fat: 25,
        meta_carbohydrates: 40,
        image_full_url: "https://example.com/images/chicken_satay.jpg",
        image_small_url: "https://example.com/images/chicken_satay_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/chicken_satay_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
    ],
    active: true,
  },
  {
    id: "14",
    title: "Main Courses",
    priority: 2,
    scope: "dine-in",
    products: [
      {
        id: "201",
        title: "Pad Thai",
        short_description: "Stir-fried noodles with shrimp and tofu.",
        description:
          "Classic Thai stir-fried noodles with shrimp, tofu, peanuts, and bean sprouts.",
        price: 1200,
        priority: 1,
        meta_weight: "350",
        meta_weight_unit: 1,
        meta_calories: 800,
        meta_proteins: 30,
        meta_fat: 35,
        meta_carbohydrates: 90,
        image_full_url: "https://example.com/images/pad_thai.jpg",
        image_small_url: "https://example.com/images/pad_thai_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/pad_thai_catalog.jpg",
        modifiers: [
          {
            id: "mod2",
            title: "Protein Choice",
            title_form: "Choose your protein",
            items: [
              {
                id: "mod_item3",
                title: "Extra Shrimp",
                price: 200,
              },
              {
                id: "mod_item4",
                title: "Chicken",
                price: 150,
              },
            ],
          },
        ],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "202",
        title: "Green Curry",
        short_description: "Spicy green curry with chicken and vegetables.",
        description:
          "Aromatic Thai green curry with chicken, bamboo shoots, and bell peppers.",
        price: 1300,
        priority: 2,
        meta_weight: "400",
        meta_weight_unit: 1,
        meta_calories: 750,
        meta_proteins: 28,
        meta_fat: 40,
        meta_carbohydrates: 70,
        image_full_url: "https://example.com/images/green_curry.jpg",
        image_small_url: "https://example.com/images/green_curry_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/green_curry_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "203",
        title: "Beef Bulgogi",
        short_description: "Korean grilled beef with rice.",
        description:
          "Tender marinated beef grilled to perfection, served with steamed rice.",
        price: 1400,
        priority: 3,
        meta_weight: "350",
        meta_weight_unit: 1,
        meta_calories: 850,
        meta_proteins: 35,
        meta_fat: 45,
        meta_carbohydrates: 80,
        image_full_url: "https://example.com/images/beef_bulgogi.jpg",
        image_small_url: "https://example.com/images/beef_bulgogi_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/beef_bulgogi_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
    ],
    active: true,
  },
  {
    id: "15",
    title: "Main Courses222",
    priority: 2,
    scope: "dine-in",
    products: [
      {
        id: "201",
        title: "Pad Thai",
        short_description: "Stir-fried noodles with shrimp and tofu.",
        description:
          "Classic Thai stir-fried noodles with shrimp, tofu, peanuts, and bean sprouts.",
        price: 1200,
        priority: 1,
        meta_weight: "350",
        meta_weight_unit: 1,
        meta_calories: 800,
        meta_proteins: 30,
        meta_fat: 35,
        meta_carbohydrates: 90,
        image_full_url: "https://example.com/images/pad_thai.jpg",
        image_small_url: "https://example.com/images/pad_thai_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/pad_thai_catalog.jpg",
        modifiers: [
          {
            id: "mod2",
            title: "Protein Choice",
            title_form: "Choose your protein",
            items: [
              {
                id: "mod_item3",
                title: "Extra Shrimp",
                price: 200,
              },
              {
                id: "mod_item4",
                title: "Chicken",
                price: 150,
              },
            ],
          },
        ],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "202",
        title: "Green Curry",
        short_description: "Spicy green curry with chicken and vegetables.",
        description:
          "Aromatic Thai green curry with chicken, bamboo shoots, and bell peppers.",
        price: 1300,
        priority: 2,
        meta_weight: "400",
        meta_weight_unit: 1,
        meta_calories: 750,
        meta_proteins: 28,
        meta_fat: 40,
        meta_carbohydrates: 70,
        image_full_url: "https://example.com/images/green_curry.jpg",
        image_small_url: "https://example.com/images/green_curry_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/green_curry_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "203",
        title: "Beef Bulgogi",
        short_description: "Korean grilled beef with rice.",
        description:
          "Tender marinated beef grilled to perfection, served with steamed rice.",
        price: 1400,
        priority: 3,
        meta_weight: "350",
        meta_weight_unit: 1,
        meta_calories: 850,
        meta_proteins: 35,
        meta_fat: 45,
        meta_carbohydrates: 80,
        image_full_url: "https://example.com/images/beef_bulgogi.jpg",
        image_small_url: "https://example.com/images/beef_bulgogi_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/beef_bulgogi_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
    ],
    active: true,
  },
  {
    id: "16",
    title: "Appetizers",
    priority: 1,
    scope: "dine-in",
    products: [
      {
        id: "101",
        title: "Spring Rolls",
        short_description: "Crispy rolls stuffed with vegetables.",
        description:
          "Delicious crispy rolls filled with a mix of fresh vegetables and served with a sweet chili sauce.",
        price: 500,
        priority: 1,
        meta_weight: "200",
        meta_weight_unit: 1,
        meta_calories: 300,
        meta_proteins: 5,
        meta_fat: 10,
        meta_carbohydrates: 40,
        image_full_url: "https://example.com/images/spring_rolls.jpg",
        image_small_url: "https://example.com/images/spring_rolls_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/spring_rolls_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "102",
        title: "Edamame",
        short_description: "Steamed soybeans with sea salt.",
        description:
          "Fresh steamed edamame beans sprinkled with sea salt. A light and healthy start to your meal.",
        price: 400,
        priority: 2,
        meta_weight: "150",
        meta_weight_unit: 1,
        meta_calories: 200,
        meta_proteins: 12,
        meta_fat: 7,
        meta_carbohydrates: 10,
        image_full_url: "https://example.com/images/edamame.jpg",
        image_small_url: "https://example.com/images/edamame_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/edamame_catalog.jpg",
        modifiers: [
          {
            id: "mod1",
            title: "Seasoning",
            title_form: "Choose your seasoning",
            items: [
              {
                id: "mod_item1",
                title: "Extra Sea Salt",
                price: 50,
              },
              {
                id: "mod_item2",
                title: "Spicy Chili Powder",
                price: 70,
              },
            ],
          },
          {
            id: "mod2",
            title: "Title",
            title_form: "Выберите соус",
            items: [
              {
                id: "mod_item1",
                title: "Кислый",
                price: 50,
              },
              {
                id: "mod_item2",
                title: "Сладкий",
                price: 70,
              },
            ],
          },
        ],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "103",
        title: "Chicken Satay",
        short_description: "Grilled chicken skewers with peanut sauce.",
        description:
          "Tender grilled chicken skewers served with a rich peanut dipping sauce.",
        price: 700,
        priority: 3,
        meta_weight: "250",
        meta_weight_unit: 1,
        meta_calories: 500,
        meta_proteins: 25,
        meta_fat: 25,
        meta_carbohydrates: 40,
        image_full_url: "https://example.com/images/chicken_satay.jpg",
        image_small_url: "https://example.com/images/chicken_satay_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/chicken_satay_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
    ],
    active: true,
  },
  {
    id: "17",
    title: "Main Courses",
    priority: 2,
    scope: "dine-in",
    products: [
      {
        id: "201",
        title: "Pad Thai",
        short_description: "Stir-fried noodles with shrimp and tofu.",
        description:
          "Classic Thai stir-fried noodles with shrimp, tofu, peanuts, and bean sprouts.",
        price: 1200,
        priority: 1,
        meta_weight: "350",
        meta_weight_unit: 1,
        meta_calories: 800,
        meta_proteins: 30,
        meta_fat: 35,
        meta_carbohydrates: 90,
        image_full_url: "https://example.com/images/pad_thai.jpg",
        image_small_url: "https://example.com/images/pad_thai_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/pad_thai_catalog.jpg",
        modifiers: [
          {
            id: "mod2",
            title: "Protein Choice",
            title_form: "Choose your protein",
            items: [
              {
                id: "mod_item3",
                title: "Extra Shrimp",
                price: 200,
              },
              {
                id: "mod_item4",
                title: "Chicken",
                price: 150,
              },
            ],
          },
        ],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "202",
        title: "Green Curry",
        short_description: "Spicy green curry with chicken and vegetables.",
        description:
          "Aromatic Thai green curry with chicken, bamboo shoots, and bell peppers.",
        price: 1300,
        priority: 2,
        meta_weight: "400",
        meta_weight_unit: 1,
        meta_calories: 750,
        meta_proteins: 28,
        meta_fat: 40,
        meta_carbohydrates: 70,
        image_full_url: "https://example.com/images/green_curry.jpg",
        image_small_url: "https://example.com/images/green_curry_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/green_curry_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "203",
        title: "Beef Bulgogi",
        short_description: "Korean grilled beef with rice.",
        description:
          "Tender marinated beef grilled to perfection, served with steamed rice.",
        price: 1400,
        priority: 3,
        meta_weight: "350",
        meta_weight_unit: 1,
        meta_calories: 850,
        meta_proteins: 35,
        meta_fat: 45,
        meta_carbohydrates: 80,
        image_full_url: "https://example.com/images/beef_bulgogi.jpg",
        image_small_url: "https://example.com/images/beef_bulgogi_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/beef_bulgogi_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
    ],
    active: true,
  },
  {
    id: "18",
    title: "Main Courses222",
    priority: 2,
    scope: "dine-in",
    products: [
      {
        id: "201",
        title: "Pad Thai",
        short_description: "Stir-fried noodles with shrimp and tofu.",
        description:
          "Classic Thai stir-fried noodles with shrimp, tofu, peanuts, and bean sprouts.",
        price: 1200,
        priority: 1,
        meta_weight: "350",
        meta_weight_unit: 1,
        meta_calories: 800,
        meta_proteins: 30,
        meta_fat: 35,
        meta_carbohydrates: 90,
        image_full_url: "https://example.com/images/pad_thai.jpg",
        image_small_url: "https://example.com/images/pad_thai_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/pad_thai_catalog.jpg",
        modifiers: [
          {
            id: "mod2",
            title: "Protein Choice",
            title_form: "Choose your protein",
            items: [
              {
                id: "mod_item3",
                title: "Extra Shrimp",
                price: 200,
              },
              {
                id: "mod_item4",
                title: "Chicken",
                price: 150,
              },
            ],
          },
        ],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "202",
        title: "Green Curry",
        short_description: "Spicy green curry with chicken and vegetables.",
        description:
          "Aromatic Thai green curry with chicken, bamboo shoots, and bell peppers.",
        price: 1300,
        priority: 2,
        meta_weight: "400",
        meta_weight_unit: 1,
        meta_calories: 750,
        meta_proteins: 28,
        meta_fat: 40,
        meta_carbohydrates: 70,
        image_full_url: "https://example.com/images/green_curry.jpg",
        image_small_url: "https://example.com/images/green_curry_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/green_curry_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
      {
        id: "203",
        title: "Beef Bulgogi",
        short_description: "Korean grilled beef with rice.",
        description:
          "Tender marinated beef grilled to perfection, served with steamed rice.",
        price: 1400,
        priority: 3,
        meta_weight: "350",
        meta_weight_unit: 1,
        meta_calories: 850,
        meta_proteins: 35,
        meta_fat: 45,
        meta_carbohydrates: 80,
        image_full_url: "https://example.com/images/beef_bulgogi.jpg",
        image_small_url: "https://example.com/images/beef_bulgogi_small.jpg",
        image_catalog_small_url:
          "https://example.com/images/beef_bulgogi_catalog.jpg",
        modifiers: [],
        flags: [],
        ingredients: [],
        recommendedIngredients: [],
        active: true,
      },
    ],
    active: true,
  },
];
