<script setup>
import { ref, watch } from 'vue';
import { debounce } from 'lodash';
import { appConfig } from "../../../config";
import axios from "axios";
import {VLabel} from "vuetify/components";
import {storeToRefs} from "pinia";
import {useMenuStore} from "@/store/MenuStore";
import {string} from "yup";

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    required: true,
  },
  errorMessages: {
    type: String,
    default: "",
    required: false
  },
  onBlur: {
    type: Function,
    default: () => {},
    required: false,
  },
  onSelect: {
    type: Function,
    default: () => {},
    required: false,
  },
  preparedCity: {
    type: string,
    default: "",
    required: false,
  }
});

const emit = defineEmits(['update:modelValue']);
const isLoading = ref(false);
const suggestions = ref([]);
const localValue = ref(''); // Для строки, введенной пользователем
const selectedSuggestion = ref(null); // Для выбранной подсказки
const {currentCity} = storeToRefs(useMenuStore())
const fetchSuggestions = debounce(async (term) => {
  if (!term) {
    suggestions.value = [];
    return;
  }
  isLoading.value = true;
  try {
    const { data } = await axios.post(`${appConfig.API_URL}/address`, {
      term: props.preparedCity ? props.preparedCity + ' ' + term : currentCity?.value.title + ' ' + term,
    });
    suggestions.value = Object.values(data);
  } catch (error) {
    console.error(error);
  }
  isLoading.value = false;
}, 600);

watch(localValue, (newValue) => {
  // Отправляем запрос на получение подсказок только для текста, введенного пользователем
  if (typeof newValue === 'string') {
    fetchSuggestions(newValue);
  } else {
    emit('update:modelValue', localValue.value);
    if (props.onSelect) {
      props.onSelect(localValue.value); // Вызываем переданный родительский обработчик
    }
  }
});

watch(() => props.modelValue, (newVal) => {
  if (selectedSuggestion.value !== newVal) {
    selectedSuggestion.value = newVal;
  }
});
</script>

<template>
  <v-label
    class="text-black opacity-100 w-100 semibold mb-0 text-start"
  >
    {{
      label
    }}
    {{ currentCity.value }}
  </v-label>
  <v-combobox
    v-model="localValue"
    class="w-100"
    :items="suggestions"
    variant="underlined"
    :loading="isLoading"
    item-title="value"
    :hide-no-data="true"
    :clear-on-select="false"
    :return-object="true"
    no-filter
    :placeholder="placeholder"
    @select="selectedSuggestion = $event"
    @blur="onBlur"
  />
  <v-card-subtitle
    v-if="errorMessages"
    class="text-customRed"
  >
    {{
      errorMessages
    }}
  </v-card-subtitle>
</template>

<style scoped>
</style>
