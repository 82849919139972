<template>
  <v-container
    ref="scrollContainer"
    class="pa-0 d-flex flex-column gr-10"
    fluid
  >
    <v-sheet class="w-100 pt-4 pt-md-9">
      <HomeBanner />

      <v-sheet
        v-if="isVisible"
        width="64px"
        height="64px"
        class="position-fixed right-0 bottom-0 mb-16 mb-lg-8 mr-4 mr-lg-8 bg-customRed rounded-circle d-flex justify-center align-center cursor-pointer"
        style="z-index: 1000"
        @click="scrollToTop"
      >
        <v-icon
          size="x-large"
          icon="mdi-chevron-up"
        />
      </v-sheet>
    </v-sheet>
    <MenuComponent />
  </v-container>
</template>

<script setup>
import MenuComponent from "@/components/Menu/MenuComponent/MenuComponent.vue";
import HomeBanner from "@/components/Home/HomeBanner/HomeBanner.vue";
import {onBeforeUnmount, onMounted, ref} from "vue";

const isVisible = ref(true);
const scrollContainer = ref(null);

const handleScroll = () => {
  const currentScrollPosition = window.scrollY;
  isVisible.value = currentScrollPosition >= 200;
}

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  })
};
</script>
