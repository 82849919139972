<script setup>
import {ref, onMounted, onBeforeUnmount, computed} from 'vue';
import MenuListNav from "@/components/Header/MenuListNav/MenuListNav.vue";
import HeaderContacts from "@/components/Header/HeaderContacts/HeaderContacts.vue";
import HeaderHours from "@/components/Header/HeaderHours/HeaderHours.vue";
import HeaderSearch from "@/components/Header/HeaderSearch/HeaderSearch.vue";
import HeaderLocalization from "@/components/Header/HeaderLocalization/HeaderLocalization.vue";
import HeaderCart from "@/components/Header/HeaderCart/HeaderCart.vue";
import MobileMenuListNav from "@/components/Header/MenuListNav/MobileMenuListNav.vue";
import {useDisplay} from "vuetify";
import HeaderMobileCart from "@/components/Header/HeaderCart/HeaderMobileCart.vue";
import {storeToRefs} from "pinia";
import {useMenuStore} from "@/store/MenuStore";

const isVisible = ref(true);
const { mobile, smAndDown, xs } = useDisplay();
const menuStore = useMenuStore();
const { currentCity } = storeToRefs(menuStore);

const contacts = computed(() => {
  return currentCity.value ? currentCity.value.contacts : [];
})

const yandex = computed(() => {
  return contacts.value.find((contact) => contact.title === 'yandex');
})

const handleScroll = () => {
  const currentScrollPosition = window.scrollY;
  isVisible.value = currentScrollPosition <= 0;
}


onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});

</script>

<template>
  <div
    class="w-100"
    :class="mobile ? 'px-3 py-4' : 'py-5 px-4'"
  >
    <v-sheet
      class="w-100 header-container pa-0 ma-0 align-center mx-auto ga-4 ga-lg-6 d-flex flex-row justify-space-between"
      :class="mobile ? 'justify-start' : 'justify-space-between'"
      no-gutters
    >
      <HeaderContacts v-if="isVisible || xs" />

      <v-sheet
        v-if="!xs"
        class="d-flex flex-row align-center ga-md-5 ga-2"
      >
        <a
          v-if="yandex"
          :href="yandex.link"
          target="_blank"
          class="cursor-pointer"
        >
          <v-img
            width="30"
            height="30"
            src="@/assets/yandex.png"
          />
        </a>

        <header-search />

        <header-localization />
      </v-sheet>

      <v-sheet
        v-if="isVisible || mobile"
        class="d-flex justify-center align-center"
        width="100%"
        :max-width="xs ? 'none' : 268"
      >
        <v-img
          :style="{maxWidth: smAndDown ? '200px' :''}"
          width="100%"
          height="auto"
          max-height="110"
          src="@/assets/Logo_Tom_Yam.svg"
        />
      </v-sheet>

      <MenuListNav v-if="!isVisible && !mobile" />

      <HeaderHours v-if="isVisible && !mobile" />

      <HeaderCart v-if="!mobile" />

      <v-sheet
        v-if="mobile"
        class="d-flex justify-end px-0 ml-auto"
        :class="mobile ? 'justify-center' :'justify-end'"
      >
        <MobileMenuListNav />
      </v-sheet>

      <header-localization v-if="xs" />
    </v-sheet>

    <header-mobile-cart v-if="mobile" />
  </div>
</template>

<style scoped>
.container {
  z-index: 1000;
}
.header-container {
  max-width: 1400px;
}
</style>
