<script setup>

</script>

<template>
  <v-sheet class="pa-0 ma-0">
    <v-img
      width="25"
      height="25"
      src="@/assets/ru.svg"
    />
  </v-sheet>
</template>

<style scoped>

</style>
