<script setup>
import BaseTitle from "@/components/base/BaseTitle/BaseTitle.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import {computed, nextTick, onMounted, ref, toRaw, watch} from "vue";
import { useMenuStore } from "@/store/MenuStore";
import {storeToRefs} from "pinia";
import {useCartStore} from "@/store/CartStore";
import {useDisplay} from "vuetify";
import BaseButton from "@/components/base/BaseButton.vue";
import {getCurrencySymbol} from "@/helpers";

// Подключение к store
const menuStore = useMenuStore();
const cartStore = useCartStore();
const { sections } = storeToRefs(menuStore);
const { items } = storeToRefs(cartStore);

// Вычисление продуктов
const products = computed(() => {
  return sections.value.reduce((result, section) => {
    const rawProducts = toRaw(section.products); // Избавляемся от Proxy
    const filteredProducts = rawProducts.filter(
        product => product.add_to_order === true
    );
    return result.concat(filteredProducts);
  }, []);
});
const { xs } = useDisplay();

// Ссылка на Swiper
const mySwiper = ref(null);

// Функция для инициализации Swiper
const onSwiper = (swiper) => {
  mySwiper.value = swiper;
};

// Функции для прокрутки
const slideNext = () => {
  if (mySwiper.value && mySwiper.value.initialized) {
    mySwiper.value.slideNext();
  }
};

const slidePrev = () => {
  if (mySwiper.value && mySwiper.value.initialized) {
    mySwiper.value.slidePrev();
  }
};

const handleAddToCart = async (product) => {
  const productFromCart = items.value.find((item) => {
    return item.product.id === product.id;
  });
  if (productFromCart) {
    await cartStore.cartItemUpdate(productFromCart.id, productFromCart.quantity + 1);
  } else {
    await cartStore.addToCart(product);
  }
}

watch(products, async () => {
  if (products.value.length > 0) {
    await nextTick(); // Ожидаем обновления DOM
    if (mySwiper.value) {
      mySwiper.value.update(); // Обновляем Swiper после изменений
    }
  }
});

// Используем onMounted для загрузки после инициализации компонента
onMounted(() => {
  if (mySwiper.value) {
    mySwiper.value.update();
  }
});
</script>


<template>
  <v-sheet class="w-100 rounded-xl">
    <v-sheet class="w-100">
      <base-title class="mb-4">
        Добавьте к заказу
      </base-title>
    </v-sheet>
    <v-sheet class=" w-100">
      <v-sheet class="position-relative w-100 h-100 px-7">
        <swiper
          v-if="products.length > 0"
          ref="mySwiper"
          class="pr-4"
          :slides-per-view="xs ? 1 : 2"
          :space-between="30"
          :loop="true"
          @swiper="onSwiper"
        >
          <swiper-slide
            v-for="(product, i) in products"
            :key="i"
            :virtual-index="i"
          >
            <v-sheet
              class="pa-1 h-100"
            >
              <v-sheet
                color="transparent"
                class="rounded-xl border-md d-flex flex-column align-center w-100 h-100"
              >
                <v-img
                  width="144"
                  height="100"
                  :src="product.image_full_url"
                />
                <v-sheet class="text-subtitle-2 semibold text-center px-2">
                  {{ product.title }}
                </v-sheet>
                <v-sheet
                  color="transparent"
                  class="text-subtitle-1 justify-space-between d-flex flex-row align-center w-100"
                >
                  <v-sheet
                    color="transparent"
                    class="px-4 semibold"
                  >
                    {{ product.meta_weight }} г.
                  </v-sheet>
                  <v-sheet
                    color="transparent"
                    class="px-4 text-h6 currency-symbol font-weight-bold"
                  >
                    {{ product.price }}&nbsp;{{ getCurrencySymbol(menuStore.currentCity) }}
                  </v-sheet>
                </v-sheet>
                <v-sheet
                  color="transparent"
                  class="my-4 w-100 d-flex align-center justify-center"
                >
                  <base-button
                    :max-width="167"
                    width="auto"
                    text="ДОБАВИТЬ"
                    @click="() => handleAddToCart(product)"
                  />
                </v-sheet>
              </v-sheet>
            </v-sheet>
          </swiper-slide>
        </swiper>
        <v-container class="custom-navigation px-4">
          <v-btn
            class="custom-button prev-button text-customRed border elevation-0"
            variant="elevated"
            density="compact"
            icon="mdi-chevron-left"
            size="large"
            color="white"
            @click="slidePrev"
          />
          <v-btn
            class="custom-button prev-button text-customRed border elevation-0"
            variant="elevated"
            density="compact"
            icon="mdi-chevron-right"
            size="large"
            color="white"
            @click="slideNext"
          />
        </v-container>
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>

<style scoped>
.custom-button {
  pointer-events: all;
}

.custom-navigation {
  position: absolute;
  top: 0;
  left: -22px;
  z-index: 10;
  width: calc(100% + 44px);
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
  padding: 0;
}
</style>
