import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/pages/HomePage.vue";
import CartPage from "@/pages/CartPage.vue";
import ProfilePage from "@/pages/ProfilePage.vue";
import CurrentOrder from "@/components/Profile/CurrentOrder/CurrentOrder.vue";
import OrdersHistory from "@/components/Profile/OrdersHistory/OrdersHistory.vue";
import EditProfile from "@/components/Profile/EditProfile/EditProfile.vue";
import CyrillicToTranslit from "cyrillic-to-translit-js";
import CityPage from "@/pages/CityPage.vue";

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
    exact: true,

  },
  {
    path: "/cart",
    name: "CartPage",
    component: CartPage,
    exact: true,
  },
  {
    path: "/profile",
    name: "ProfilePage",
    component: ProfilePage,
    exact: true,
    children: [
      {
        path: 'order',
        name: 'CurrentOrder',
        component: CurrentOrder,
      },
      {
        path: 'history',
        name: 'OrderHistory',
        component: OrdersHistory,
      },
      {
        path: 'edit',
        name: 'EditProfile',
        component: EditProfile,
      },
      {
        path: '',
        redirect: { name: 'CurrentOrder' }, // перенаправление по умолчанию на текущий заказ
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        el: to.hash,
        behavior: "smooth",
        top: 100,
      };
    }
  },
});

export const createRoutesForCities = async (cities) =>  {
  cities.forEach((city) => {
    const route = CyrillicToTranslit().transform(city.title).toLowerCase();
    router.addRoute({
      path: `/${route}`,
      name: `CityPage-${city.id}`,
      component: CityPage,
      props: { city },
    });
  });
  await router.replace(router.currentRoute.value.fullPath);
}

export default router;
