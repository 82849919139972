export const GENDERS = [
    {
        title: 'Мужской',
        value: 'MALE',
    },
    {
        title: 'Женский',
        value: 'FEMALE',
    },
    {
        title: 'Другой',
        value: 'ANOTHER',
    }
]
