<template>
  <v-sheet class="d-flex flex-column align-items-start px-2">
    <div class="text-subtitle-1 text-uppercase">
      Режим работы
    </div>
    <div>10:00—22:00 (ежедневно)</div>
  </v-sheet>
</template>

<script setup>

</script>
