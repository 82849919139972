import { defineStore } from 'pinia';
import apolloClient from "../../apolloClient";
import {
    GET_ORDERS
} from '@/api/queries';
import {ref} from "vue";

export const useProfileStore = defineStore('profileStore', () => {
    const orders = ref(null);
    const isLoading = ref(false);
    const isMobileDrawerOpen = ref(false);

    const getOrders = async (active, first = 20) =>  {
        isLoading.value = true;
        try {
            const response = await apolloClient.query({
                query: GET_ORDERS,
                variables: {
                    active,
                    first
                }
            });

            orders.value = response.data.orders;
        } catch (err) {
            this.error = "Failed to fetch orders";
        } finally {
            isLoading.value = false;
        }
    };

    const toggleMobileDrawer = () => {
        isMobileDrawerOpen.value = !isMobileDrawerOpen.value;
    }

    return {
        orders,
        isLoading,
        isMobileDrawerOpen,
        getOrders,
        toggleMobileDrawer
    };
});
