<script setup>
import router from "@/router";
import BaseButton from "@/components/base/BaseButton.vue";
import {useCartStore} from "@/store/CartStore";
import {storeToRefs} from "pinia";
import {useMenuStore} from "@/store/MenuStore";
import {getCurrencySymbol} from "@/helpers";

const { total_price } = storeToRefs(useCartStore());
const { currentCity } = storeToRefs(useMenuStore())

const handleNavigateToCart = () => {
  router.push({
    path: "/cart",
  });
};
</script>

<template>
  <div class="d-flex flex-row align-center justify-end ga-4">
    <v-img
      src="@/assets/btn_shop.svg"
      width="28"
      height="33"
      class="cursor-pointer"
      @click="handleNavigateToCart"
    />
    <v-sheet
      class="semibold cursor-pointer currency-symbol"
      @click="handleNavigateToCart"
    >
      {{ total_price }}&nbsp;{{ getCurrencySymbol(currentCity) }}
    </v-sheet>
    <base-button
      class="ml-6"
      :max-width="192"
      text="ОФОРМИТЬ ЗАКАЗ"
      @click="handleNavigateToCart"
    />
  </div>
</template>
