// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";
import { ru } from "vuetify/locale"; // Импортируем локализацию для русского языка
export default createVuetify({
  theme: {
    defaultTheme: "tomYumTheme",
    themes: {
      tomYumTheme: {
        dark: false,
        colors: {
          customRed: "#e41d30",
          customGray: "#818181",
        },
      },
    },
  },
  locale: {
    locale: "ru",
    messages: { ru },
  },
});
